import React from "react";
import {useTranslation} from "react-i18next";
import {TemplateParameter} from "./TemplateParameter";
import {TemplatePage} from "./TemplatePage";
import {useFieldArray, useFormContext} from "react-hook-form";


export const Template = (props: {  showParameter: boolean, parameterGui: string, pageGui: string  }) => {
    const {t} = useTranslation();
    const {control, formState: {errors}} = useFormContext()

    const {fields, insert, move, append, remove} = useFieldArray({
        name: "tpl.pages",
        control
    });


    const addPage = (index: number) => {
        if (index < fields.length - 1) {
            insert(index + 1, {content: []});
        } else {
            append({content: []});
        }
    };

    const removePage = (index: number) => {
        remove(index);
    };

    const movePageUp = (index: number) => {
        if (index > 0) {
            move(index, index - 1);
        }
    };

    const movePageDown = (index: number) => {
        if (index < fields.length - 1) {
            move(index, index + 1);
        }
    };

    return (<>
        {props.showParameter && <TemplateParameter  gui={props.parameterGui}/>}

        {fields.map((field, index) => {
                return (<div key={field.id}>
                    <TemplatePage gui={props.pageGui}
                                 pageNumber={index}
                                  addPageFunc={addPage} removePageFunc={removePage}
                                  movePageUpFunc={movePageUp} movePageDownFunc={movePageDown}
                                  numOfPages={fields.length}/></div>)
            }
        )}
    </>);
}