import React from 'react';
import Button from 'react-bootstrap/Button';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';


function ConfirmationModal({show, onHide, title, text, onConfirm}) {
    const {t} = useTranslation();

    return (
        <Modal onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
        }} show={show} onHide={onHide} centered>
            <Modal.Header>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{text}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={onHide}>{t('abort')}</Button>
                <Button onClick={onConfirm}>{t('confirm')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;