import {Col, Row} from "react-bootstrap";
import {SimpleInput} from "./components/form/SimpleInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Utils} from "./utils/Utils";
import {SimpleFormStatusAlert} from "./components/form/SimpleFormStatusAlert";


type PasswordAndPasswordRepeatType = {
    password: string
    passwordRepeat: string
}

export const InitialPasswordForm = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [done, setDone] = useState(false);

    const methods = useForm<PasswordAndPasswordRepeatType>({
        resolver: yupResolver(yup.object({
            password: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("newPassword")}))
                .min(8, t("error.validation.SizeMin", {min: "8"}))
                .matches(/[A-Z]/, t('error.validation.passwordMustContainAtLeastOneUppercaseLetter'))
                .matches(/[a-z]/, t('error.validation.passwordMustContainAtLeastOneLowercaseLetter'))
                .matches(/[0-9]/, t('error.validation.passwordMustContainAtLeastOneNumber'))
                .matches(/[!@#$%^&*(),.?":{}|<>]/, t('error.validation.passwordMustContainAtLeastSpecialChar')),
            passwordRepeat: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("passwordRepeat")})).oneOf([yup.ref("password")],
                t("error.validation.passwordsMustMatch"))
        }))
    });


    const onSubmit = async (values: PasswordAndPasswordRepeatType) => {
        await Utils.simpleSubmitHandler(values, methods.setError, t, "/api/setupPassword")
            .then((data:any) => {
                methods.reset(data);
                setDone(true);
                setTimeout(() => {
                    navigate("/app/dashboard");
                }, 3000);
            }).catch((error) => {
                console.log("ERROR:::", error);
            });

    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    return (
        <>
            {!done && <Row>
                <Col className="col-12 col-md-6">
                    <div className={"pt-2 h2"}>{t("setup0")}</div>
                    <div className={"pt-1"}>{t("setup1")}</div>
                    <div className={"pt-1"}>{t("setup2")}</div>
                </Col>
            </Row>}
            <FormProvider {...methods}>
                <Form className={"pt-3"} onSubmit={methods.handleSubmit(onSubmit, onError)}>
                    {!done && <Row>
                        <Col className="col-12 col-md-6">
                            <SimpleInput type={"password"} name={"password"} i18nKey={"newPassword"}/>
                        </Col>
                    </Row>}
                    {!done && <Row>
                        <Col className="col-12 col-md-6">
                            <SimpleInput type={"password"} name={"passwordRepeat"} i18nKey={"passwordRepeat"}/>
                        </Col>
                    </Row>}
                    <SimpleFormStatusAlert/>

                    {!done && <Button disabled={methods.formState.isSubmitting} type="submit" className="mt-3"
                                      variant="primary">{t("save")}</Button>}

                    {done && <div>{t("oneMomentPlease")}</div>}
                </Form>
            </FormProvider>
        </>
    )
};