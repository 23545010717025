import Form from "react-bootstrap/Form";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

export const SimpleInput = (props: {
    type: string,
    name: string,
    i18nKey: string
    onBlur?: any,
}) => {
    const {t} = useTranslation();
    const { register,getValues,formState: {errors} } = useFormContext();
    let options:{
        onBlur?: any
    } = {};
    if (props.onBlur !== undefined) {
        options.onBlur = props.onBlur
    }

    return (<Form.Group className="mb-3">
        <Form.Label>{t(props.i18nKey)}</Form.Label>
        <Form.Control type={props.type}  {...register(props.name,options)}
                      className="form-control rounded-0" data-1p-ignore
                      isInvalid={!!errors[props.name]?.message}/>
        <Form.Control.Feedback
            type="invalid">{errors[props.name]?.message as string}</Form.Control.Feedback>
    </Form.Group>);
}
