import {Headline} from '../../components/headline/Headline';
import {useTranslation} from 'react-i18next';
import {Container} from "react-bootstrap";
import DataTable from "../../2ndlib/components/databable/DataTable";
import DataColumn from "../../2ndlib/components/databable/DataColumn";
import EditInfo from "../../2ndlib/components/databable/EditInfo";
import DeleteInfo from "../../2ndlib/components/databable/DeleteInfo";
import AddInfo from "../../2ndlib/components/databable/AddInfo";
import React from "react";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {DisplayGroupModel} from "../../models/DisplayGroupModel";
import {ArrowLeft} from "react-bootstrap-icons";
import DuplicateInfo from "../../2ndlib/components/databable/DuplicateInfo";

export const DisplayGroupList = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const displayRenderer = (row: DisplayGroupModel) => {
      return <i>{row.displays?.map(display => display.name).join(', ')}</i>;
    }

    return (
        <main className="px-3">
            <Container>
                <Headline>{t("displayGroups")}</Headline>
                <div className={"mt-3"}>
                    <DataTable dataUrl={"/api/v2/displayGroups?withDisplays=true"} idField={"id"} noDataMessage={t("noDataFound")}>
                        <DataColumn label={t("nameDisplayGroup")} width="25%" field="name"/>
                        <DataColumn label={t("assignedDisplays")} renderer={displayRenderer}/>


                        <EditInfo view="/app/displaygroup/{id}" title={t("editDisplayGroup")}/>
                        <DeleteInfo serviceUrl="/api/displayGroups/{id}" title={t("deleteDisplayGroup")}
                                    text={t("sureToDisplayGroup")}/>
                        <AddInfo view="/app/displayGroup/0" title={t("newDisplayGroup")}/>
                        <DuplicateInfo serviceUrl="/api/v2/displayGroups/{id}/duplicate"/>
                    </DataTable>
                </div>
                <Button className="me-2" variant="outline-secondary" onClick={e => {
                    navigate("/app/dashboard");
                }}><ArrowLeft className="pointer"/></Button>
                <Button className="me-2" type="button" onClick={(e) => {
                    navigate("/app/displayGroup/0");
                }} variant="outline-secondary">{t("newDisplayGroup")}</Button>
            </Container>
        </main>
    );
}

