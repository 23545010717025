import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React from "react";
import {SubHeadline} from "../../../components/subheadline/SubHeadline";
import {Col, FloatingLabel, Row} from "react-bootstrap";
import {useDynamicParameterGUIContext} from "../DynamicParameterGUIContext";

export const OpticalLineSplitting = () => {
    const {register, watch, control, getValues, formState: {errors}} = useFormContext()
    const {fields} = useDynamicParameterGUIContext()
    const {t} = useTranslation();
    return (
        <>
            <SubHeadline>{t("opticalLineSplitting")}</SubHeadline>
            <Row>
                {fields.map((field:any, index:number) => {
                    return (
                        <Col key={field.id} className="col-12 col-md-3">
                            <FloatingLabel
                                controlId={`floatingInput-${index}`}
                                label={`Zeile ${index + 1} ab Zeichen`}
                                className="mb-3"
                            >
                                <Form.Select {...register(`tpl.parameter.opticalLineSplitting.${index}.splitAt` as const)}>
                                    {Array.from({length: 60}, (_, i) => <option key={"noc" + i}
                                                                                value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>)
                })}
            </Row>
        </>
    );
}