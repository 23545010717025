import {Headline} from '../../components/headline/Headline';
import {useTranslation} from 'react-i18next';
import {Container} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ArrowLeft} from "react-bootstrap-icons";

export const Imprint = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <main className="px-3">
            <Container>
                <Headline>{t('imprint')}</Headline>
                <div className="lead fw-normal">

                    <div className="pt-2">Schauf GmbH</div>
                    <div>{t("impDisplaySystems")}</div>

                    <div className="pt-3">{t("impAdministration")}</div>
                    <div>Schauf GmbH</div>
                    <div>Friedrich-Ebert-Str. 127, D-42117 Wuppertal</div>

                    <div className="pt-3">{t("impProductionService")}</div>
                    <div>Schauf GmbH</div>
                    <div>c/o Assist Blechtechnik GmbH & Co. KG</div>
                    <div>Leverkuser Str. 65, D-42897 Remscheid</div>

                    <div className="pt-3">Tel. +49 (0) 202 / 947 938-0</div>
                    <div>Fax +49 (0) 202 / 947 938-99</div>
                    <div><a href="mailto:info@schauf.eu">info@schauf.eu</a></div>

                    <div className="fw-bold pt-3">Service Hotline</div>

                    <div>{t("impServiceHotlineTime")}</div>

                    <div>+49 (0) 202 / 947 938 – 95</div>
                    <div className={"mt-3"}>
                        <Button className="me-2" variant="outline-secondary" onClick={e => {
                            navigate("/app/dashboard");
                        }}><ArrowLeft className="pointer"/></Button>
                    </div>
                </div>
            </Container>
        </main>
    );
}

