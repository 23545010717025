import {IUserData} from "../models/IUserData";

export namespace UserUtils {

    export function isAdmin(auth: IUserData | null) {
        return (auth === undefined ||  auth === null) ? false : (auth.role === "admin");
    }

    export function isEditor(auth: IUserData | null) {
        return (auth === undefined ||  auth === null) ? false : (auth.role === "editor");
    }


    export function getUserId(auth: IUserData | null) {
        return  (auth === undefined ||  auth === null) ? null : auth.id;
    }

}