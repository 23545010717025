import React, {PropsWithChildren} from 'react';


export const SubHeadline = (props: PropsWithChildren) => {
    return (
        <div className="mb-3 p-2 bg-body-secondary">
            <div>{props.children}</div>
        </div>
     );
   }
