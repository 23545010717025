
import React from "react";
import PropTypes from "prop-types";

const AddInfo= (props:{view:string,title:string}) => <></>;

AddInfo.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

AddInfo.defaultProps = {
    __TYPE: 'AddInfo',
};


export default AddInfo;