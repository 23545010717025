import React, {Children, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ButtonGroup, Table} from "react-bootstrap";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import axiosInstance from "../../api/axios";
import {PencilSquare, PlusLg} from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";


function DataTable({idField, dataUrl, path = "", noDataMessage = "", children}) {
    const navigate = useNavigate();
    let [data, setData] = useState(["none"]);
    const arrayChildren = Children.toArray(children);
    const dataColumns = arrayChildren.filter((item) => {
        return item.props.__TYPE === "DataColumn";
    });
    const editInfo = arrayChildren.filter((item) => {
        return item.props.__TYPE === "EditInfo";
    });
    const deleteInfo = arrayChildren.filter((item) => {
        return item.props.__TYPE === "DeleteInfo";
    });
    const addInfo = arrayChildren.filter((item) => {
        return item.props.__TYPE === "AddInfo";
    });
    const duplicateInfo = arrayChildren.filter((item) => {
        return item.props.__TYPE === "DuplicateInfo";
    });

    useEffect(() => {
        loadList();
    }, []);


    const loadList = function () {
        axiosInstance.get(dataUrl).then(function (response) {
            setData(path !== "" ? getValue(response.data, path) : response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getValue = function (obj, path) {
        const keys = path.split('.');

        // Reduziere den Pfad auf den gesuchten Wert
        return keys.reduce((accumulator, currentKey) => {
            return (accumulator && accumulator[currentKey] !== undefined) ? accumulator[currentKey] : undefined;
        }, obj);
    }


    return (<Table striped className="table-hover">
        <thead>
        <tr>
            {dataColumns.map((item, index) => {
                return <th style={{width: item.props.width ? item.props.width : "auto"}}
                           key={index}>{item.props.label}</th>
            })}
            <th className={"text-end"}><PlusLg itle={addInfo[0].props.title} className="ms-3 me-1 pointer"
                                             onClick={e => {
                                                 navigate(addInfo[0].props.view);
                                             }}/></th>
        </tr>
        </thead>
        <tbody>
        {Array.isArray(data) && data.length > 0 && data.map((item, index) => {
            return (<DataTableRow key={"dr" + index} idField={idField}
                                  item={item} index={index}
                                  dataColumns={dataColumns} editInfo={editInfo[0]}
                                  deleteInfo={deleteInfo[0]} duplicateInfo={duplicateInfo[0]} loadList={loadList}/>)
        })}
        {(!Array.isArray(data) || (Array.isArray(data) && data.length === 0)) && <tr>
            <td colSpan={dataColumns.length + 1}>{noDataMessage}</td>
        </tr>}
        </tbody>
    </Table>);
}

function DataTableRow({item, index, dataColumns, idField, editInfo, deleteInfo, duplicateInfo, loadList}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = React.useState(false);
    const hasEditInfo = editInfo && editInfo.props;
    const hasDeleteInfo = deleteInfo && deleteInfo.props;
    const hasDuplicateInfo = duplicateInfo && duplicateInfo.props;

    const emptyText = () => {return "";};
    const deleteText = () => {
        let dText = deleteInfo.props.text;
        for (let col in dataColumns) {
            dText = dText.replace("{" + dataColumns[col].props.field + "}", item[dataColumns[col].props.field]);
        }
        return dText;
    }
    const deleteNoPossibleText = () => {
        return t("operationNotPossibleReason");
    }

    const duplicateItem = (e) => {
        let id = item[idField];
        axiosInstance.get(process.env.REACT_APP_API_HOST + duplicateInfo.props.serviceUrl.replace('{id}', id))
            .then(function (response) {
                loadList();
                setShow(false);
            }).catch(function (error) {
            console.log(error);
        });
    }

    const deleteItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = item[idField];
        axiosInstance.delete(process.env.REACT_APP_API_HOST + deleteInfo.props.serviceUrl.replace('{id}', id))
            .then(function (response) {
                loadList();
                setShow(false);
            }).catch(function (error) {
            setConfirmationModalInfo({title:t("operationNotPossible"),text:deleteNoPossibleText});
            console.log(error);
        });
    }

    const [confirmationModalInfo, setConfirmationModalInfo] = React.useState({text:emptyText,title:deleteInfo.props.title});

    return (<tr className="pointer">
        {dataColumns.map((column, idx) => {
            if (column.props.renderer) {
                return <td onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(editInfo.props.view.replace('{id}', item[idField]));
                }} key={'c' + idx}>{column.props.renderer(item)}</td>
            } else {
                return <td onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(editInfo.props.view.replace('{id}', item[idField]));
                }} key={'c' + idx}>{item[column.props.field]}</td>
            }
        })}
        <td className="text-end">
            <Dropdown className="pt-0" as={ButtonGroup}>
                <Button className="border-0 pt-0" size={"sm"} onClick={e => {
                    navigate(editInfo.props.view.replace('{id}', item[idField]));
                }} variant="outline-secondary"><PencilSquare title={editInfo.props.title} className="pointer"/></Button>
                <Dropdown.Toggle split  className="pt-0 pb-0 border-0" variant="outline-secondary"/>
                <Dropdown.Menu>
                    {hasDuplicateInfo &&<><Dropdown.Item
                        onClick={e => {duplicateItem(e)}}>{t("duplicate")}</Dropdown.Item>
                        <Dropdown.Divider /></>}
                    <Dropdown.Item onClick={e => {
                        setConfirmationModalInfo({text:deleteText,title:deleteInfo.props.title});
                        setShow(true);
                    }}>{t("delete")}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmationModal onConfirm={deleteItem} show={show} onHide={e => {
                e.stopPropagation();
                e.preventDefault();
                setShow(false);
            //    setConfirmationModalInfo(defaultMessage);
            }} title={confirmationModalInfo.title} text={confirmationModalInfo.text()}/>
        </td>
    </tr>)
}

export default DataTable;