import React from "react";

export  interface XMLNode {
    nodeName: string;
    nodeValue: string | null;
    nodeType: number;
    childNodes: XMLNodeList;
    attributes: NamedNodeMap;
}

export interface XMLNodeList extends Array<XMLNode> {}


export interface DynamicGUIProps {
    gui: string;
}

export const parseXMLToReact = (node: XMLNode, index: number = 0,t:any,componentMap:any): React.ReactNode => {
    if (!node) return null;

    const componentName = node.nodeName;
    const Component = componentMap[componentName];

    if (!Component) return null;

    const attributes: { [key: string]: string } = {};
    for (let i = 0; i < node.attributes.length; i++) {
        const attr = node.attributes[i];
        attributes[attr.name] = attr.value;
    }

    const children = Array.from(node.childNodes).map((childNode, idx) => {
        if (childNode.nodeType === Node.TEXT_NODE) {
            return childNode.nodeValue && childNode.nodeValue.trim().length > 0 ? t(childNode.nodeValue.trim()) : "";
        } else {
            return parseXMLToReact(childNode as XMLNode, idx,t,componentMap);
        }
    });

    return <Component key={componentName + index} {...attributes}>{children}</Component>;
};