import React from "react";
import {Col, Container, InputGroup, ListGroup, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext, useWatch} from "react-hook-form";

export const TimeRange = () => {
    const {t} = useTranslation();
    const {pageNumber, numOfPages} = useDynamicPageGUIContext();
    const {
        register, control,
        formState: {errors}
    } = useFormContext()

    const timeRange = useWatch({
        control, name: `tpl.pages.${pageNumber}.timeRange`
    });

    const month1Value = useWatch({
        control, name: `tpl.pages.${pageNumber}.timeRangeMonths1`
    });

    const month2Value = useWatch({
        control, name: `tpl.pages.${pageNumber}.timeRangeMonths2`
    });

    const findNumDays = (monthNumber: number): number => {
        const monthObj = months.find((month) => month.value === monthNumber);
        return monthObj ? monthObj.days : 31;
    }

    const months = [
        {label: t("january"), value: 1, days: 31},
        {label: t("february"), value: 2, days: 29},
        {label: t("march"), value: 3, days: 31},
        {label: t("april"), value: 4, days: 30},
        {label: t("may"), value: 5, days: 31},
        {label: t("june"), value: 6, days: 30},
        {label: t("july"), value: 7, days: 31},
        {label: t("august"), value: 8, days: 31},
        {label: t("september"), value: 9, days: 30},
        {label: t("october"), value: 10, days: 31},
        {label: t("november"), value: 11, days: 30},
        {label: t("december"), value: 12, days: 31},
    ];

    const weekDays = [
        {label: t("monday"), value: 1, short: "mon"},
        {label: t("tuesday"), value: 2, short: "tue"},
        {label: t("wednesday"), value: 3, short: "wed"},
        {label: t("thursday"), value: 4, short: "thu"},
        {label: t("friday"), value: 5, short: "fri"},
        {label: t("saturday"), value: 6, short: "sat"},
        {label: t("sunday"), value: 7, short: "sun"}
    ];

    const monthsObj = months.map((month) =>
        <option key={"timeRange-m1" + month.value} value={month.value}>{month.label}</option>);

    return (
        <div className="mb-3">

            <div className="mb-3">
                <Form.Check
                    {...register(`tpl.pages.${pageNumber}.timeRange`)}
                    type={"checkbox"}
                    id={"checkbox-set-display-times-" + pageNumber}
                    label={t("setDisplayTimes")}
                />
            </div>
            {timeRange === true && <Container className={"bg-body-tertiary py-3"}>
                <Form.Group className="mb-3">
                    <Form.Label>{t("dateInterval")}</Form.Label>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className={"d-md-none"}>{t("from")}</div>
                            <InputGroup>
                                <InputGroup.Text className={"d-none d-md-block"}>{t("from")}</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeDays1`)}>
                                    {Array.from({length: findNumDays(parseInt(month1Value))}, (_, i) => <option
                                        key={"timeRange-m2-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeMonths1`)}>
                                    {monthsObj}
                                </Form.Select>
                            </InputGroup>
                        </Col>

                        <Col xs={12} md={6}>
                            <div className={"mt-2 d-md-none"}>{t("until")}</div>
                            <InputGroup>
                                <InputGroup.Text className={"d-none d-md-block"}>{t("until")}</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeDays2`)}>
                                    {Array.from({length: findNumDays(parseInt(month2Value))}, (_, i) => <option
                                        key={"timeRange-m3-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeMonths2`)}>
                                    {monthsObj}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{t("timeInterval")}</Form.Label>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className={"d-md-none"}>{t("from")}</div>
                            <InputGroup>
                                <InputGroup.Text className={"d-none d-md-block"}>{t("from")}</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeHours1`)}>
                                    {Array.from({length: 23}, (_, i) => <option
                                        key={"timeRange-m4-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <InputGroup.Text>:</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeMinutes1`)}>
                                    {Array.from({length: 59}, (_, i) => <option
                                        key={"timeRange-m5-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <InputGroup.Text>{t("clock")}</InputGroup.Text>
                            </InputGroup>
                        </Col>

                        <Col xs={12} md={6}>
                            <div className={"mt-2 d-md-none"}>{t("until")}</div>
                            <InputGroup>
                                <InputGroup.Text className={"d-none d-md-block"}>{t("until")}</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeTimeHours2`)}>
                                    {Array.from({length: 23}, (_, i) => <option
                                        key={"timeRange-m6-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <InputGroup.Text>:</InputGroup.Text>
                                <Form.Select {...register(`tpl.pages.${pageNumber}.timeRangeMinutes2`)}>
                                    {Array.from({length: 59}, (_, i) => <option
                                        key={"timeRange-m7-" + i} value={i + 1}>{i + 1}</option>)}
                                </Form.Select>
                                <InputGroup.Text>{t("clock")}</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{t("activeDays")}</Form.Label>

                    <ListGroup horizontal className="flex-wrap">
                        {weekDays.map((day) =>
                            <Form.Check
                                className={"pt-2 pt-md-0 pe-3"} {...register(`tpl.pages.${pageNumber}.timeRangeWeekDays.${day.short}`)}
                                type={"checkbox"} label={day.label}/>
                        )}

                    </ListGroup>

                </Form.Group>


            </Container>}
        </div>);
}

