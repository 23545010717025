
import React from "react";
import PropTypes from "prop-types";

const DeleteInfo= (props:{serviceUrl:string,text:string, title:string}) => <></>;

DeleteInfo.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

DeleteInfo.defaultProps = {
    __TYPE: 'DeleteInfo',
};


export default DeleteInfo;