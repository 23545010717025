import {useFormContext} from "react-hook-form";
import {useDynamicParameterGUIContext} from "../DynamicParameterGUIContext";
import {useTranslation} from "react-i18next";
import React from "react";

import {Col, Row} from "react-bootstrap";
import {Div} from "../../common/elements/Div";
import {H5} from "../../common/elements/H5";
import {NumberOfCharsPerLineSelector} from "./NumberOfCharsPerLineSelector";
import {NumberOfLinesSelector} from "./NumberOfLinesSelector";
import Form from "react-bootstrap/Form";


export const DefaultParameter= () => {
    const {register, watch, control, getValues, formState: {errors}} = useFormContext()
    const {fields} = useDynamicParameterGUIContext()
    const {t} = useTranslation();
    return (<>

        <Div className="card mb-4 rounded-3 shadow-sm">
            <Div className="card-header py-3">
                <h5 className="my-0 fw-normal">{t("settings")}</h5></Div>
            <Div className="card-body">
                <Row>
                    <Col className="col-12 col-md-6">
                        <NumberOfLinesSelector/>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <NumberOfCharsPerLineSelector/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-md-6">
                <Form.Check
                    {...register(`tpl.parameter.multiColor`)}
                    type={"checkbox"}
                    id={"multicolor-setting" + Math.random()}
                    label={t("multicolor")}/>
                        <Form.Check
                            {...register(`tpl.parameter.bold`)}
                            type={"checkbox"}
                            id={"bold-setting" + Math.random()}
                            label={t("wideText")}/>
                        <Form.Check
                            {...register(`tpl.parameter.flash`)}
                            type={"checkbox"}
                            id={"flash-setting" + Math.random()}
                            label={t("flashingText")}/>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <Form.Group className="mb-3">
                            <Form.Label>{t("version")}</Form.Label>
                            <Form.Select {...register("tpl.parameter.version")}>
                                {Array.from({length: 2}, (_, i) => <option key={"vs"+i} value={i + 1}>V{i + 1}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </Div>
        </Div>

    </>
);
}