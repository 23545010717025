import {Headline} from "../../components/headline/Headline";
import {Container} from "react-bootstrap";
import DataTable from "../../2ndlib/components/databable/DataTable";
import DataColumn from "../../2ndlib/components/databable/DataColumn";
import EditInfo from "../../2ndlib/components/databable/EditInfo";
import DeleteInfo from "../../2ndlib/components/databable/DeleteInfo";
import AddInfo from "../../2ndlib/components/databable/AddInfo";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {UserModel} from "../../models/UserModel";
import {ArrowLeft} from "react-bootstrap-icons";

export const UserList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const accessLevelRenderer = (row: UserModel) => {
        return <>
            {t("role."+row.role)}
        </>;
    }

    return (
        <main className="px-3">
            <Container>
                <Headline>{t("userList")}</Headline>
                <div className={"mt-3"}>
                    <DataTable dataUrl={"/api/v2/users"}  idField={"id"} noDataMessage={t("noDataFound")}>
                        <DataColumn label={t("username")} field="username" />
                        <DataColumn label={t("firstname")} field="firstname" />
                        <DataColumn label={t("lastname")} field="lastname"/>
                        <DataColumn label={t("role.role")} renderer={accessLevelRenderer}/>
                        <EditInfo view="/app/user/{id}" title={t("editUser")}/>
                        <DeleteInfo serviceUrl="/api/users/{id}" title={t("deleteUser")}
                                    text={t("sureToDeleteUser")} />
                        <AddInfo view="/app/user/0" title={t("newUser")}/>
                    </DataTable>
                </div>
                <Button className="me-2" variant="outline-secondary" onClick={e => {
                    navigate("/app/dashboard");
                }}><ArrowLeft className="pointer"/></Button>
                <Button className="me-2" type="button" onClick={(e) => {
                    navigate("/app/user/0");
                }} variant="outline-secondary">{t("newUser")}</Button>
            </Container>
        </main>
    );
}