import React from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Button from "react-bootstrap/Button";

function InfoModal({infoModal, setInfoModal}) {
    const {t} = useTranslation();

    return (
        <Modal show={infoModal.show} centered backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    {infoModal.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{infoModal.text}</p>
            </Modal.Body>
            {infoModal.close && <Modal.Footer>
                <Button variant={"primary"} onClick={
                    event => {
                        event.preventDefault();
                        event.stopPropagation();
                        setInfoModal( {...infoModal, show: false} );
                    }
                }>{t('close')}</Button>
            </Modal.Footer>}
        </Modal>
    );
}

export default InfoModal;