import React from "react";
import Form from "react-bootstrap/Form";
import {useFormContext, useWatch} from "react-hook-form";
import {useDynamicPageGUIContext} from "../DynamicPageGUIContext";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {LightningChargeFill, PencilSquare, XLg} from "react-bootstrap-icons";


export const MultiLineSplittableForm2 = () => {
    const {t} = useTranslation();
    const {
        register,
        control,
        setFocus,
        formState: {errors}
    } = useFormContext()
    const {pageNumber} = useDynamicPageGUIContext();
    const [visibleRow, setVisibleRow] = React.useState<number>(-1);

    const templateParameter = useWatch({
        control,
        name: "tpl.parameter"
    });
    const pageContent = useWatch({
        control,
        name: "tpl.pages." + pageNumber
    });

    React.useEffect(() => {
        if (visibleRow >-1) {
            setFocus(`tpl.pages.${pageNumber}.content.${visibleRow}.text`);
        }
    }, [visibleRow])


    const numLines = templateParameter.numLines;
    const numCharsPerLine = templateParameter.numCharsPerLine;
    const splittings = templateParameter.opticalLineSplitting;
    const lineSpacings = templateParameter.lineSpacing;

    const bgColorS1 = templateParameter.bgColorS1;
    const colorS1 = templateParameter.colorS1;
    const bgColorS2 = templateParameter.bgColorS2;
    const colorS2 = templateParameter.colorS2;

    const rows = [];

    for (let y = 0; y < numLines; y++) {

        let distTop =  "0"
       if (lineSpacings && lineSpacings[y] && lineSpacings[y].distTop) {
            distTop = lineSpacings[y].distTop;
        }
        let cols = [], sVal = parseInt(splittings[y].splitAt),
            text = "";
        if (pageContent.content[y]) {
            text = pageContent.content[y].text || "\u00A0";
        }
        let textArray = text.replaceAll(" ", "\u00A0").split("");
        for (let x = 0; x < numCharsPerLine; x++) {
            let letter = textArray[x] || "\u00A0";
            cols.push(<div style={{backgroundColor: (x >= sVal - 1) ? bgColorS2 : bgColorS1, color: (x >= sVal - 1) ? colorS2 : colorS1}}
                className={" mt-1 d-inline-block font-monospace p-1 border"}>{letter}</div>);
        }
        cols.push(<div className={"ps-2 d-inline-block"}><PencilSquare className="pointer"/></div>);

        rows.push(<table className={"mt-"+distTop+" mx-auto"}>
            <tbody>
            <tr>
                <td className={"pt-2 pe-2 align-top"}>{y + 1 < 10 ? "\u00A0" + (y + 1) : y + 1}</td>
                <td>
                    <div className={"d-md-none"}>{t("row")} {y + 1}</div>
                    <div className={"pointer"} onClick={(e) => {
                        e.preventDefault()
                        setVisibleRow(y);

                    }}>{cols}</div>
                    <div className={((visibleRow === y) ? "":" d-none" )}>
                        <table>
                            <tbody><tr><td className={"w-100"}>
                                <Form.Control type={"text"}  {...register(`tpl.pages.${pageNumber}.content.${y}.text`)}
                                              className="form-control rounded-0" data-1p-ignore/>
                                { /*
                                <div className={"mt-2"}><Button variant="secondary" title={t("flash")} size="sm">
                                    <LightningChargeFill className="pointer" onClick={
                                        (e) => {
                                            e.preventDefault();
                                        }
                                    }/>
                                </Button></div> */}
                            </td>
                                <td className={"ps-2 pt-1 align-top"}><XLg className="pointer" onClick={
                                (e) => {
                                    e.preventDefault();
                                    setVisibleRow(-1);
                                }
                            }/></td></tr></tbody>
                        </table>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>);
    }
    return (<div className="my-4">{rows}</div>);
}