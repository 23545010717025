export namespace HtmlUtils {


    export function tidy(htmlString: string): string {




        return htmlString.replace(/<br\s*\/?>/gi,'');
    }

    export function updateWideFontDivWiths(htmlElement:HTMLElement) {
        htmlElement.querySelectorAll("span.rte-font-wide")
            .forEach((span) => {
                if (span.textContent) {
                    let lValue = (span.textContent.length * 29);
                    if (lValue > 0) {
                        (span as HTMLElement).style.width = lValue + "px";
                    }
                } else {
                    (span as HTMLElement).remove();
                }
            });
    }


    export function countCharsInRteFontWide(htmlString: string): number   {
        // Create a DOM parser
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Select all spans with the class "rte-font-wide"
        const spans = doc.querySelectorAll('span.rte-font-wide');

        let charCount = 0;

        // Iterate over each span and count its text content length
        spans.forEach(span => {
            charCount += span.textContent?.length || 0;
        });

        return charCount;
    }


    export function countAllChars(htmlString: string): number {
        // Create a DOM parser
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Get the text content of the entire document
        const textContent = doc.body.textContent || '';

        // Return the length of the text content
        return textContent.length;
    }

}