
import {useTranslation} from "react-i18next";
import React from "react";

import {useDynamicThumbnailGUIContext} from "../DynamicThumbnailGUIContext";
import {stripHtml} from "../../../utils/Utils";


export const Thumb1 = () => {
    const {display} = useDynamicThumbnailGUIContext()
    const {t} = useTranslation();
    return (
        <div className={"p-3 display-preview-tile"}>
            {display.tpl.pages && display.tpl.pages[0].content.map((content:any, index:number) => {
                return <div key={display.id+"_"+index}>
                    <div className={"fnt-display-preview-tile fst-italic"}>{stripHtml(content.text1)}</div>
                    <div className={"fnt-display-preview-tile fst-italic"}>{stripHtml(content.text2)}</div>
                </div>
            })}
        </div>
    );
}