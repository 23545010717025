import axios from "axios";
import {Utils} from "../utils/Utils";


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 10000,
});


axiosInstance.interceptors.request.use(
    config => {
        const cVal = Utils.getCookie("_auth");
        if (cVal !== null) {
            console.log("Setting Authorization header to: "+cVal);
            config.headers.Authorization = "Bearer "+cVal;
        }
        return config;
    }, error => {
    //   Handle request error
    return Promise.reject(error);
 }
);

axiosInstance.interceptors.response.use(
    response => {
        // Any status code that lie within the range of 2xx causes this function to trigger
        return response;
    },
    error => {
        // Any status codes that falls outside the range of 2xx causes this function to trigger
        // Handle response error
        if (error.response) {
            // Server responded with a status other than 200 range
        //    console.error('Response error:', error.response.status, error.response.data);
        } else if (error.request) {
            // No response was received
         //   console.error('Request error:', error.request);
        } else {
            // Something happened in setting up the request
      //      console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
