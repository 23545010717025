import React, {useState, useEffect, useRef, MutableRefObject} from 'react';
import classnames from 'classnames';
import {useFormContext, useWatch} from "react-hook-form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {HtmlUtils} from "../../../../utils/HtmlUtils";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import {useIsMount} from "../../../../hooks/useIsMount";

export const ContentEditableWithCounter = (props: {
    flashTextVisible: boolean,
    clsName: string,
    name: string,
    charLimit: number,
    toolbarButtonClickData: any
}) => {
    const [html, setHtml] = useState<string>("");
    const {t} = useTranslation();
    const [numChars, setNumChars] = useState<number>(0);
    const contentEditableRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const {
        register,
        control,
        setValue,
        getValues,
        formState: {errors}
    } = useFormContext()
    const isMount = useIsMount();

    const {pageNumber} = useDynamicPageGUIContext();
    const isCenter = useWatch({
        control,
        name: `tpl.pages.${pageNumber}.center`
    });

    const handleInput = (e: any) => {
        if (e.charCode === 13) {
            e.preventDefault()
            return;
        } else {
            if (contentEditableRef.current) {
                setHtml(contentEditableRef.current.innerHTML);
            }
        }
    };

    const handleOnKeyDown = (e: any) => {
        let keyCode = e.which || e.keyCode;
        if (keyCode === 13) {
            e.preventDefault()
        }
    };

    useEffect(() => {
        if (contentEditableRef.current && isMount) {
            contentEditableRef.current.innerHTML = getValues(props.name);
        }
    }, []);

    useEffect(() => {
        if (!isMount) {
            let numBoldChars = HtmlUtils.countCharsInRteFontWide(html);
            let numChars = HtmlUtils.countAllChars(html);
            setNumChars(Math.round(numChars - numBoldChars + (numBoldChars * 1.5)));
            setValue(props.name, HtmlUtils.tidy(html),{ shouldDirty: true });
        }
    }, [html]);

    useEffect(() => {
        if (contentEditableRef.current && !isMount) {
            setHtml(contentEditableRef.current.innerHTML);
        }
    }, [props.toolbarButtonClickData]);



    return (
        <>
            <div spellCheck="false"
                onKeyDown={handleOnKeyDown}
                onInput={handleInput}
                ref={contentEditableRef}
                dangerouslySetInnerHTML={{__html: ""}}
                contentEditable
                className={classnames(props.clsName + " form-control " + (isCenter ? "text-center " : " "),
                    {"rte-text-flash-on": props.flashTextVisible, "rte-text-flash-off": !props.flashTextVisible})}
            />
            {(numChars > props.charLimit) && <div className={"mt-0 mb-3 text-danger"}>{t("toManyChars")}</div>}
            <Form.Control  className="d-none" as="textarea" {...register(props.name)} rows={3}/>
        </>
    );
};
