import {HtmlUtils} from "./HtmlUtils";

export namespace SelectionUtils {


    export const wrapSelectedText = (clsName: string) => {
        // let classesArr = getClassesOfSelection();

        if (clsName === "rte-text-red" || clsName === "rte-text-yellow" || clsName === "rte-text-green") {
            removeHtmlTagsWithClassFromSelection("rte-text-red");
            removeHtmlTagsWithClassFromSelection("rte-text-yellow");
            removeHtmlTagsWithClassFromSelection("rte-text-green");
        }


        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (range.collapsed) return;

            const span = document.createElement('span');
            span.className = clsName;
            try {
                range.surroundContents(span);
            } catch (e) {
                const documentFragment = range.extractContents();
                span.appendChild(documentFragment);
                range.insertNode(span);
            }
            // selection.removeAllRanges();
        }
    };


    export const removeHtmlTagsFromSelection = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedContent = range.extractContents();
            const textContent = selectedContent.textContent || '';
            // Create a text node with the plain text
            const textNode = document.createTextNode(textContent);
            // Insert the text node at the original range
            range.insertNode(textNode);

            // Adjust the range to select the inserted text node
            range.selectNode(textNode);
            selection.removeAllRanges();
            selection.addRange(range);
            return selection;
        }
        return null;
    };

    export const removeFormat = (): String => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const commonAncestorContainer: Node = range.commonAncestorContainer;
            let nde = null;
            if (commonAncestorContainer.nodeType === Node.TEXT_NODE) {
                const parentElement = commonAncestorContainer.parentElement;
                if (parentElement) {
                    parentElement.normalize();
                    nde = parentElement;
                }
            } else {
                nde = commonAncestorContainer;
            }

            const editableDiv =
                (nde as HTMLElement).closest('div.led-text') as HTMLElement;

            if (editableDiv) {
                const startContainer = range.startContainer;
                const endContainer = range.endContainer;
                const startOffset = range.startOffset;
                const endOffset = range.endOffset;

                // Extract the selected content
                const selectedContent = range.cloneContents();
                const selectedHTMLContainer = document.createElement('div');
                selectedHTMLContainer.appendChild(selectedContent);
                const selectedHTML = selectedHTMLContainer.innerHTML;
                const selectedText = selection.toString();

                // Get the HTML before the selection
                const beforeRange = document.createRange();
                beforeRange.setStartBefore(editableDiv.firstChild!);
                beforeRange.setEnd(startContainer, startOffset);
                const beforeContent = beforeRange.cloneContents();
                const beforeHTMLContainer = document.createElement('div');
                beforeHTMLContainer.appendChild(beforeContent);
                const beforeHTML = beforeHTMLContainer.innerHTML;

                // Get the HTML after the selection
                const afterRange = document.createRange();
                afterRange.setStart(endContainer, endOffset);
                afterRange.setEndAfter(editableDiv.lastChild!);
                const afterContent = afterRange.cloneContents();
                const afterHTMLContainer = document.createElement('div');
                afterHTMLContainer.appendChild(afterContent);
                const afterHTML = afterHTMLContainer.innerHTML;

                // Construct the new HTML
                const newHTML = beforeHTML + selectedText + afterHTML;
                editableDiv.innerHTML = newHTML;
                return newHTML;
            }
        } else {
            console.log('No text selected');
        }
        return "none";
    }


    export const removeHtmlTagsWithClassFromSelection = (clsName: string) => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedContent = range.cloneContents();

            const elementsToRemove: HTMLElement[] = [];
            selectedContent.querySelectorAll(`.${clsName}`).forEach(element => {
                elementsToRemove.push(element as HTMLElement);
            });

            elementsToRemove.forEach(element => {
                const parent = element.parentNode;
                while (element.firstChild) {
                    parent?.insertBefore(element.firstChild, element);
                }
                parent?.removeChild(element);
            });

            range.deleteContents();
            range.insertNode(selectedContent);

            // Clear the selection
            // selection.removeAllRanges();
        }
    };

    export const getClassesOfSelection = (): string[] => {
        const selection = window.getSelection();
        const classes: Set<string> = new Set();

        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Traverse the nodes within the range
            const traverseNodes = (node: Node) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const element = node as HTMLElement;
                    element.classList.forEach(cls => classes.add(cls));
                }

                node.childNodes.forEach(traverseNodes);
            };

            traverseNodes(range.commonAncestorContainer);
        }

        return Array.from(classes);
    };


    export const unwrapSelectedText = (clsName: string) => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const commonAncestor = range.commonAncestorContainer;

            let parentElement: HTMLElement | null = null;
            if (commonAncestor.nodeType === Node.TEXT_NODE) {
                parentElement = commonAncestor.parentElement;
            } else if (commonAncestor.nodeType === Node.ELEMENT_NODE) {
                parentElement = commonAncestor as HTMLElement;
            }

            if (parentElement && parentElement.classList.contains(clsName)) {
                const spanElement = parentElement;
                const parentNode = spanElement.parentNode;

                if (parentNode) {
                    while (spanElement.firstChild) {
                        parentNode.insertBefore(spanElement.firstChild, spanElement);
                    }
                    parentNode.removeChild(spanElement);

                    // Clear the selection
                    //selection.removeAllRanges();
                }
            }
        }
    };


    export const stripHtmlFromSelection = () => {
        removeHtmlTagsFromSelection();

        //  document.execCommand("removeFormat", false, "");


    };


}