import {Headline} from "../../components/headline/Headline";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React, {BaseSyntheticEvent, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {AxiosResponse} from "axios";
import {useLoaderData} from "react-router";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {DisplayModel} from "../../models/DisplayModel";
import {Utils} from "../../2ndlib/utils/Utils";
import {SimpleFormStatusAlert} from "../../2ndlib/components/form/SimpleFormStatusAlert";
import {SimpleInput} from "../../2ndlib/components/form/SimpleInput";
import axiosInstance from "../../2ndlib/api/axios";
import {TemplateManager} from "../../displayTemplates/TemplateManager";
import {FormIntegratedDisplayGroupList} from "../../components/displayList/FormIntegratedDisplayGroupList";
import {SubHeadline} from "../../components/subheadline/SubHeadline";
import {SimpleTextArea} from "../../2ndlib/components/form/SimpleTextArea";
import {ArrowLeft} from "react-bootstrap-icons";
import InfoModal from "../../2ndlib/components/InfoModal/InfoModal";
import {InfoModalInfo} from "../../models/InfoModalInfo";
import useWebSocket from "react-use-websocket";
import {uid} from "../../utils/Utils";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {PublishDisplayMessageModel} from "../../models/PublishDisplayMessageModel";


export const Display = () => {
    const {t} = useTranslation();
    const disply = useLoaderData() as any;
    const [display, setDisplay] = useState<any>(disply);
    const [infoModal, setInfoModal] = useState<InfoModalInfo>(new InfoModalInfo());
    const [messageId, setMessageId] = useState(uid());

    const {
        sendJsonMessage,
    } = useWebSocket(process.env.REACT_APP_API_WEB_SOCKET!!, {
        onOpen: () => console.log('opened'),
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            const responseType = (data.messageType);
            emitCustomEvent(responseType, data);
        },
        shouldReconnect: (closeEvent) => true,
        share: true
    });


    const navigate = useNavigate();
    const methods = useForm<any>({
        resolver: yupResolver(yup.object({
            id: yup.number(),
            name: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("name")})),
            communication: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("communication")})),
            ip: yup.string().trim(),
            port: yup.number().typeError(t("error.validation.invalidNumberFormat")).integer(),
            serialPort: yup.number().typeError(t("error.validation.invalidNumberFormat")).integer(),
            baudRate: yup.number().typeError(t("error.validation.invalidNumberFormat")).integer(),
            comments: yup.string().trim()
        })),
        defaultValues: display
    });

    const saveAndPublish = (e?: BaseSyntheticEvent) => {
        methods.handleSubmit(onSubmit, onError)(e);
    }

    useCustomEventListener("PublishDisplayMessage", (data: PublishDisplayMessageModel) => {
        if (data.messageId === messageId) {
            if (data.errorMessage && data.errorMessage.trim().length > 0) {
                setInfoModal({
                    ...infoModal,
                    text: data.errorMessage,
                    title: t("errorOccurred"),
                    show: true,
                    close: true
                });
            } else {
                setInfoModal({
                    ...infoModal,
                    show: false
                });
            }
        }
    });

    const onSubmit = async (values: DisplayModel, e?: React.BaseSyntheticEvent) => {
        let values2 = JSON.parse(JSON.stringify(values)) as DisplayModel;
        values2.tpl = JSON.stringify(values2.tpl);
        values2.templateId = display.templateId;
        delete (values2 as any)._tmp_dirty_marker;

        await Utils.simpleSubmitHandler(values2, methods.setError, t,
            "/api/v2/displays").then((data: any) => {
            values.id = data.id;
            methods.reset(values);

            if (e?.target.name === "saveAndPublish") {
                methods.setValue("_tmp_dirty_marker", uid(), {shouldDirty: true})
                sendJsonMessage({
                    messageId: messageId,
                    messageType: "PublishDisplayMessage",
                    displayId: data.id
                });
                setInfoModal({
                    ...infoModal,
                    show: true,
                    title: t("transferDataToDisplay"),
                    text: t("oneMomentPlease"),
                    close: false
                });
            }
        }).catch((error) => {
            console.log("ERROR:::", error);
        });
    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    let communication = methods.watch("communication");

    React.useEffect(() => {
        if (methods.getValues("id") === undefined) {
            methods.setFocus("name");
        }

    }, []);

    return (
        <main className="px-3">
            <Container>
                <Headline>
                    {methods.getValues("id") !== undefined && t("editDisplay")}
                    {methods.getValues("id") === undefined && t("newDisplay")}
                </Headline>
                <div className={"mt-3"}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                            <Row>
                                <Col className="col-12">
                                    <SimpleInput type={"text"} name={"name"} i18nKey={"name"}/>
                                </Col>
                            </Row>
                            <Card className={"mb-3"}>
                                <Card.Body>
                                    <Row>
                                        <Col className="col-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t("communication")}</Form.Label>
                                                <Form.Select
                                                    className="form-control" {...methods.register("communication")}>
                                                    <option value="tcpIp">{t("tcpIp")}</option>
                                                    <option value="serial">{t("serial")}</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {communication === "tcpIp" && <Row>
                                        <Col className="col-12 col-md-8">
                                            <SimpleInput type={"text"} name={"ip"} i18nKey={"ipOrDnsName"}/>
                                        </Col>
                                        <Col className="col-12 col-md-4">
                                            <SimpleInput type={"text"} name={"port"} i18nKey={"port"}/>
                                        </Col>
                                    </Row>}
                                    {communication === "serial" && <Row>
                                        <Col className="col-12 col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t("connection")}</Form.Label>
                                                <Form.Select
                                                    className="form-control" {...methods.register("serialPort")}>
                                                    {Array.from({length: 15}, (_, i) => <option key={"s_port" + i}
                                                                                                value={i + 1}>{i + 1}</option>)}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12 col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t("baudRate")}</Form.Label>
                                                <Form.Select
                                                    className="form-control" {...methods.register("serialBaudRate")}>
                                                    <option key="b1200" value="1200">1200</option>
                                                    <option key="b2400" value="2400">2400</option>
                                                    <option key="b4800" value="4800">4800</option>
                                                    <option key="b9600" value="9600">9600</option>
                                                    <option key="b1440" value="14400">14400</option>
                                                    <option key="b19200" value="19200">19200</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>}

                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("displayAddress")}</Form.Label>
                                        <Form.Select
                                            className="form-control" {...methods.register("displayAddress")}>
                                            {Array.from({length: 64}, (_, i) => <option key={"daddress" + i}
                                                                                        value={i + 1}>{i + 1}</option>)}
                                        </Form.Select>
                                    </Form.Group>

                                    { /*
                                    <Button variant="outline-secondary" onClick={
                                        (e) => {
                                            e.preventDefault();

                                        }
                                    }>{t("testCommunication")}</Button>
                                    */}
                                </Card.Body>
                            </Card>


                            <Row>
                                <Col className="col-12">
                                    <SimpleTextArea name={"comments"} i18nKey={"comments"}/>
                                </Col>
                            </Row>
                            {display.displayGroups && display.displayGroups.length > 0 &&
                                <><SubHeadline>
                                    {t("selectedDisplayGroupsAssignedToThisDisplay")}
                                </SubHeadline>
                                    <div className={"mb-3"}>
                                        <FormIntegratedDisplayGroupList displayGroups={display.displayGroups}
                                                                        showDisplays={false}/>
                                    </div>
                                </>
                            }

                            {/* <TemplateSelector setDisplay={setDisplay} display={display}/> */}
                            <TemplateManager showParameter={true} parameterGui={display.parameterGui}
                                             pageGui={display.pageGui}/>

                            <SimpleFormStatusAlert/>

                            <Button className="mb-2 me-2" disabled={methods.formState.isSubmitting}
                                    variant="outline-secondary" onClick={e => {
                                navigate("/app/displays");
                            }}><ArrowLeft className="pointer"/></Button>
                            <Button disabled={methods.formState.isSubmitting} name="save" type="submit"
                                    className="mb-2 me-2"
                                    variant="primary">{t("save")}</Button>

                            {(methods.getValues("id") !== undefined && methods.getValues("id") !== null) &&
                                <Button disabled={methods.formState.isSubmitting} name="saveAndPublish" type="button"
                                        className="mb-2 me-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            saveAndPublish(e);
                                        }}
                                        variant="primary">{t("saveAndPublish")}</Button>
                            }
                        </Form>
                    </FormProvider>
                </div>
            </Container>
            <InfoModal infoModal={infoModal} setInfoModal={setInfoModal}/>
        </main>
    );
}

export async function loader(obj: any) {
    let id = parseInt(obj.params.id);
    let url = `/api/v2/displays/${id}?widthUnAssignedDisplayGroups=true`;
    const uVal = obj.request.url;
    const iVal = uVal.indexOf("/0?t=");
    if (iVal > -1) {
        const templateId = uVal.substring(iVal + 5);
        url = url + "&templateId=" + templateId;
    }

    return await axiosInstance.get(url)
        .then(function (response: AxiosResponse<any, any>) {
            let display = response.data as any;
            display.tpl = JSON.parse(display.tpl);
            return display;
        })
        .catch(function (error: any) {
            console.error("ERROR:::", error);
            throw new Error(error.response);
        });
}
