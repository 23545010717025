import React, {useState} from 'react';
import logo from '../../schauf_logo_breit.svg';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {Copyright} from '../../components/copyright/Copyright';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import {InitialPasswordForm} from "../../2ndlib/InitialPasswordForm";
import axiosInstance from "../../2ndlib/api/axios";
import i18n from "i18next";
import {useLoaderData} from "react-router";
import {AppSettings} from "../../models/AppSettings";
import {ConfigUtils} from "../../utils/ConfigUtils";
import AppConfig from "../../App";

export const Login: React.FC = () => {

    ConfigUtils.update(useLoaderData() as AppSettings);

    type CredentialType = {
        username: string
        password: string
    }

    type TokenType = {
        accessToken: string
        authUserState: string
    }

    const {t} = useTranslation();

    const navigate = useNavigate();
    const signIn = useSignIn();
    const [alert, setAlert] = useState<string>("");
    const [showInitialPasswordSetup, setShowInitialPasswordSetup] = useState<boolean>(false);
    const {
        register, handleSubmit,
        setError,
        formState: {errors, isSubmitting},
    } = useForm<CredentialType>({
        resolver: yupResolver(yup.object({
            username: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("username")})),
            password: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("password")})).min(8, t("error.validation.SizeMin", {min: "8"}))
        }))
    });


    const onSubmit = async (values: CredentialType) => {
        const token = await loginUser({
            username: values.username,
            password: values.password
        });
        if (token !== null) {
            if (signIn({
                auth: {
                    token: token.accessToken,
                    type: "Bearer"
                },
                userState: JSON.parse(token.authUserState)
            })) {

                const uState = JSON.parse(token.authUserState);
                await i18n.changeLanguage(uState.language);

                if (uState.needsPasswordInit) {
                    setShowInitialPasswordSetup(true);
                } else {
                    navigate('/app/dashboard');
                }
            } else {
                console.log('Fehler');
            }
        }
    };

    async function loginUser(credentials: CredentialType): Promise<TokenType | null> {
        return axiosInstance.post("/api/v2/login", credentials)
            .then(function (response: any) {
                console.log(response.status);
                return response.data;
            })
            .catch(function (error: any) {
                console.log("ERROR", error);
                if (error.response) {
                    let status = error.response.status;
                    if (status === 401) {
                        setAlert(t("invalidUsernameOrPassword"));
                        return null;
                    } else {
                        setAlert(t("errorDuringLogin"));
                        return null;
                    }
                } else {
                    setAlert(t("errorDuringLogin"));
                    return null;
                }
            });
    }

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    return (
        <>
            <div className="slim-form text-center h-100">
                <div className="form-signin w-100 m-auto">
                    <div className={"mb-4"}>
                        <img src={logo} alt="" width="175"/>
                    </div>
                    <h1 className="h4 pt-4 fw-normal">{t('welcome')}</h1>
                    <Form onSubmit={handleSubmit(onSubmit, onError)} className="mt-4">
                        <Form.Group className="form-floating">
                            <Form.Control type="text"
                                          className="form-control rounded-0 rounded-top" {...register("username")} />
                            <Form.Label>{t("username")}</Form.Label>
                        </Form.Group>
                        <Form.Group className="form-floating">
                            <Form.Control type="password"
                                          className="form-control rounded-0 rounded-bottom" {...register("password")}/>
                            <Form.Label>{t("password")}</Form.Label>
                        </Form.Group>
                        <Button disabled={isSubmitting} type="submit" className="mt-4 w-100 btn btn-lg"
                                variant="primary">{t("submit")}</Button>
                    </Form>

                    {alert && <Alert variant="danger" className="mt-4">{alert}</Alert>}

                    <div className="mt-4 d-none">
                        {t("notRegistered")} <span className="pointer text-decoration-underline" onClick={e => {
                        navigate('/register')
                    }}>{t('registerHere')}</span>
                    </div>

                    {AppConfig.emailCommunication && <div className="mt-4 mb-4">
                    <span className="pointer text-decoration-underline" onClick={e => {
                        navigate("/forget")
                    }}>{t("forgetPassword")}</span>
                    </div>
                    }

                    <p className="mt-4 mb-3 text-body-secondary"><Copyright/></p>
                </div>
            </div>
            <Modal show={showInitialPasswordSetup} fullscreen={true}
                   onHide={() => setShowInitialPasswordSetup(false)}>

                <Modal.Body>
                    <div className="container">
                        <div className={"pt-2 pb-2"}>
                            <img src={logo} alt="" width="150"/>
                        </div>
                        <hr/>

                        <InitialPasswordForm/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export async function loader(obj: any) {
    return await axiosInstance.get("/api/v2/read/configuration")
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error: any) {
            throw new Error(error.response);
        });
}

