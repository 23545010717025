import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import {TemplateModel} from "../../models/TemplateModel";
import axiosInstance from "../../2ndlib/api/axios";
import {AxiosResponse} from "axios";
import {useTranslation} from "react-i18next";

export const TemplateSelect = (props: { setSelectedTemplate: any }) => {
    const [templates, setTemplates] = React.useState([] as TemplateModel[]);
    const {t} = useTranslation();
    React.useEffect(() => {
        axiosInstance.get(`/api/templates`)
            .then(function (response: AxiosResponse<any, any>) {
                const tpls= response.data._embedded.templates;
                setTemplates(tpls);
            })
            .catch(function (error: any) {
                console.error("ERROR:::", error);
                throw new Error(error.response);
            });

    }, []);

    return (
        <Form.Select onChange={(e) => {
            const templateId: number = parseInt(e.target.value);
            templates.forEach((template: TemplateModel) => {
                if (template.id === templateId) {
                    props.setSelectedTemplate(template);
                }
            });
        }}>
            <option key={"-1"} hidden>{t("selectOne")}</option>
            {templates.map((template: TemplateModel) => {
                return <option key={template.id} value={template.id}>{template.name}</option>
            })}
        </Form.Select>
    )
}