import {Headline} from '../../components/headline/Headline';
import {useTranslation} from 'react-i18next';
import {Container} from "react-bootstrap";
import DataTable from "../../2ndlib/components/databable/DataTable";
import DataColumn from "../../2ndlib/components/databable/DataColumn";
import EditInfo from "../../2ndlib/components/databable/EditInfo";
import DeleteInfo from "../../2ndlib/components/databable/DeleteInfo";
import AddInfo from "../../2ndlib/components/databable/AddInfo";
import React from "react";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {ArrowLeft} from "react-bootstrap-icons";

export const TemplateList = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    return (
        <main className="px-3">
            <Container>
                <Headline>{t("templates")}</Headline>
                <div className={"mt-3"}>
                    <DataTable dataUrl={"/api/templates"} path={"_embedded.templates"} idField={"id"} noDataMessage={t("noDataFound")}>
                        <DataColumn label={t("nameTemplate")} width="25%" field="name"/>
                        <EditInfo view="/app/template/{id}" title={t("editTemplate")}/>
                        <DeleteInfo serviceUrl="/api/v2/templates/{id}" title={t("deleteTemplate")}
                                    text={t("sureToDeleteTemplate")}/>
                        <AddInfo view="/app/template/0" title={t("newTemplate")}/>
                    </DataTable>
                </div>
                <Button className="me-2" variant="outline-secondary" onClick={e => {
                    navigate("/app/dashboard");
                }}><ArrowLeft className="pointer"/></Button>
                <Button className="me-2" type="button" onClick={(e) => {
                    navigate("/app/template/0");
                }} variant="outline-secondary">{t("newTemplate")}</Button>
            </Container>
        </main>
    );
}

