import {Headline} from "../../components/headline/Headline";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import {SimpleInput} from "../../2ndlib/components/form/SimpleInput";
import {SimpleFormStatusAlert} from "../../2ndlib/components/form/SimpleFormStatusAlert";
import {Utils} from "../../2ndlib/utils/Utils";
import {useNavigate} from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {IUserData} from "../../models/IUserData";
import {UserUtils} from "../../utils/UserUtils";
import {ArrowLeft} from "react-bootstrap-icons";

export const ChangePassword = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const auth = useAuthUser<IUserData>();



    type FormValues = {
        password: string
        newPassword: string
        newPasswordRepeat: string
    }

    const methods = useForm<FormValues>({
        resolver: yupResolver(yup.object({
            password: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("password")})),
            newPassword: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("newPassword")}))
                .min(8, t("error.validation.SizeMin", {min: "8"}))
                .matches(/[A-Z]/, t('error.validation.passwordMustContainAtLeastOneUppercaseLetter'))
                .matches(/[a-z]/, t('error.validation.passwordMustContainAtLeastOneLowercaseLetter'))
                .matches(/[0-9]/, t('error.validation.passwordMustContainAtLeastOneNumber'))
                .matches(/[!@#$%^&*(),.?":{}|<>]/, t('error.validation.passwordMustContainAtLeastSpecialChar')),
            newPasswordRepeat: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("passwordRepeat")})).oneOf([yup.ref("newPassword")],
                t("error.validation.passwordsMustMatch"))
        })),
        defaultValues: {
            password: "",
            newPassword: "",
            newPasswordRepeat: ""
        }
    });

    const onSubmit = async (values: FormValues) => {
        const userId = UserUtils.getUserId(auth);
        if (userId !== null) {
            await Utils.simpleSubmitHandler(values, methods.setError, t,
                "/api/v2/users/"+userId+"/updatePassword").then((data: any) => {
                methods.reset();
            }).catch((error) => {
                console.log("ERROR:::", error);
            });
        }
    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    React.useEffect(() => {
        methods.setFocus("password");
    }, []);


    return (
        <main className="px-3">
            <Container>
                <Headline>{t("changePassword")}</Headline>
                <div className={"mt-3"}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                            <SimpleInput type={"password"} name={"password"} i18nKey={"currentPassword"}/>
                            <Row>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"password"} name={"newPassword"} i18nKey={"newPassword"}/>
                                </Col>
                                <Col className="col-12 col-md-6">
                                    <SimpleInput type={"password"} name={"newPasswordRepeat"} i18nKey={"passwordRepeat"}/>
                                </Col></Row>
                            <SimpleFormStatusAlert/>
                            <Button className="me-2" variant="outline-secondary" onClick={e => {
                                navigate("/app/dashboard");
                            }}><ArrowLeft className="pointer"/></Button><Button disabled={methods.formState.isSubmitting} type="submit"
                                    variant="primary">{t("save")}</Button>
                        </Form>
                    </FormProvider>
                </div>
            </Container>
        </main>
    );
}