import React from "react";
import {useFormContext} from "react-hook-form";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {useDynamicParameterGUIContext} from "../DynamicParameterGUIContext";


export const NumberOfLinesSelector = () => {
    const {register, formState: {errors}} = useFormContext()
    const {t} = useTranslation();
    const {updateNumLines} = useDynamicParameterGUIContext()
    return (
        <Form.Group className="mb-3">
            <Form.Label>{t("numLines")}</Form.Label>
            <Form.Select { ...register("tpl.parameter.numLines", {
                onChange: (e) => {updateNumLines()}
            })}>
                {Array.from({length: 16}, (_, i) => <option key={"nl"+i} value={i + 1}>{i + 1}</option>)}
            </Form.Select>
        </Form.Group>
    );
}