import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";


import {DynamicGUIProps, parseXMLToReact, XMLNode} from "../common/Xml2ReactTransformer";
import {H5} from "../common/elements/H5";
import {Div} from "../common/elements/Div";
import {Thumb1} from "./elements/Thumb1";
import {Thumb2} from "./elements/Thumb2";
import {TextExtractor} from "./elements/TextExtractor";


export const DynamicThumbnailGUI: React.FC<DynamicGUIProps> = ({ gui}) => {

    const componentMap: { [key: string]: React.FC<any> } = {
        "Row": Row,
        "Col": Col,
        "Div": Div,
        "H5":H5,
        "Thumb1":Thumb1,
        "Thumb2":Thumb2,
        "TextExtractor":TextExtractor
    };

    const {t} = useTranslation();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(gui, "text/xml");
    const rootElement = xmlDoc.documentElement as unknown as XMLNode;
    return parseXMLToReact(rootElement,0,t,componentMap);
};
