import React from "react";
import Form from "react-bootstrap/Form";
import {useFormContext} from "react-hook-form";
import {useDynamicPageGUIContext} from "../DynamicPageGUIContext";
import {ButtonGroup, ButtonToolbar, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";


export const MultiLine = () => {
    const {register, watch,
        formState: {errors}} = useFormContext()
    const {pageNumber} = useDynamicPageGUIContext();
    const numLines = watch("tpl.parameter.numLines");

    const getLines = () =>
        Array.from({length: numLines}, (_, i) => <div key={i} className={"pb-1"}>
        <Row>
            <Col>
            <Form.Control type={"text"}  {...register(`tpl.pages.${pageNumber}.content.${i}.text`)}
                          className="font-monospace form-control rounded-0" data-1p-ignore/>
            </Col>
            <Col className={"bg-info"}>
                <Form.Control type={"text"}  {...register(`tpl.pages.${pageNumber}.content.${i}.text1`)}
                              className="font-monospace form-control rounded-0" data-1p-ignore/>
            </Col>
            </Row>
        </div>);

    return <div className={"pt-3"}>
        <ButtonToolbar className={"mb-2"}>
            <ButtonGroup className="me-2" size={"sm"}>
                <Button variant="secondary">Blinken</Button>
                <Button variant="secondary">Breitschrift</Button>
            </ButtonGroup>
        </ButtonToolbar>
        {getLines()}
    </div>;
}