import React from "react";
import Form from "react-bootstrap/Form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {useFormContext, useWatch} from "react-hook-form";


export const EffectsSelector = () => {
    const {t} = useTranslation();
    const {pageNumber} = useDynamicPageGUIContext();
    const {
        register,
        formState: {errors}
    } = useFormContext()

    return (
        <Form.Group className="mb-3">
            <Form.Label>{t("effect")}</Form.Label>

            <Form.Select {...register(`tpl.pages.${pageNumber}.effect`)}>
                <option value="auto">AUTO</option>
                <option value="appear">APPEAR</option>
                <option value="wipe">WIPE</option>
                <option value="open">OPEN</option>
                <option value="lock">LOCK</option>
                <option value="rotate">ROTATE</option>
                <option value="right">RIGHT</option>
                <option value="left">LEFT</option>
                <option value="rollUp">ROLL UP</option>
                <option value="rollDown">ROLL DOWN</option>
                <option value="pingPong">PING PONG</option>
                <option value="fillUp">FILL UP</option>
                <option value="paint">PAINT</option>
                <option value="fadeIn">FADE IN</option>
                <option value="jump">JUMP</option>
                <option value="slide">SLIDE</option>
            </Form.Select>
        </Form.Group>);
}
