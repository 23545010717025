import React, {useState} from "react";
import logo from '../../schauf_logo_breit.svg';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Copyright} from "../../components/copyright/Copyright";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import axios from "axios";
import i18n from "i18next";

export const ForgottenPasswordRequest = () => {
    const {t} = useTranslation();
    const [saved, setSaved] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate();
    const schema = yup.object({
        email: yup.string().email().trim().required(),
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (values:any) => {
        values.svr= process.env.REACT_APP_API_FRONTEND;
        values.language = i18n.language;

        axios.post("/api/v2/password/forgotten", values)
            .then(function (response) {
                if (response.data.successful) {
                    setSaved(true);
                } else {
                    setError(true);
                }
            })
            .catch(function (error) {
                console.log("ERROR:::", error);

            });
    };

    const onError = (error:any) => {
        console.log("ERROR:::", error);
    };

    return (
        <div className="slim-form text-center h-100">
            <div className="form-signin w-100 m-auto">
                <img onClick={() => {
                    navigate("/login")
                }} className="pointer mb-4" src={logo} alt="" width="175"/>
                <h1 className="h3 mb-3 fw-normal">{t("resetPassword")}</h1>
                <div className={saved || error ? "d-none" : ""}>
                    <Form onSubmit={handleSubmit(onSubmit, onError)}>
                        <Form.Group className="form-floating">
                            <Form.Control type="text" {...register("email")}
                                          className="form-control rounded-0 rounded-top rounded-bottom"
                                          isInvalid={!!errors.email?.message}/>
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            <Form.Label>{t("username")}</Form.Label>
                        </Form.Group>
                        <Button type="submit" className="mt-4 w-100 btn btn-lg"
                                variant="primary">{t("submit")}</Button>
                        <Button type="button" onClick={e => {
                            navigate("/login");
                        }} className="mt-2 w-100 btn btn-lg" variant="secondary">{t("abort")}</Button>
                    </Form>
                </div>
                <div className={!saved ? "d-none" : ""}>{t("resetPasswordConfirmationText")}</div>
                <div className={!error ? "d-none" : ""}>{t("resetPasswordNotPossibleBecauseUserHasTemporaryPassword")}</div>
                <div className="mt-4 mb-3 text-body-secondary"><Copyright/></div>
            </div>
        </div>);
}

