import React from "react";
import Form from "react-bootstrap/Form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

export const FeatureSelector = () => {
    const {t} = useTranslation();
    const {pageNumber} = useDynamicPageGUIContext();
    const {
        register
    } = useFormContext()

    return (
        <Form.Group className="mb-3">
            <Form.Label>{t("feature")}</Form.Label>
            <Form.Select {...register(`tpl.pages.${pageNumber}.feature`)}>
                <option value="textOnly">{t("textOnly")}</option>
                <option value="time">{t("timeInFirstLine")}</option>
            </Form.Select>
        </Form.Group>);
};