

 export function uid(){
     return Date.now().toString(36) + Math.random().toString(36).substr(2);
 }

 export function randomNumber(){
     return Date.now();
 }


 export function negativeRandomNumber(){
     return randomNumber()*-1
 }
 export function isUndefined(val) {
    return  (typeof val == "undefined") || (val === null);
 }

 export function stripHtml(html) {
    if (html === null || html === undefined) {
        return "";
    }
    return html.replace(/(<([^>]+)>)/gi, "");
 }
 export function isDefined(val) {
     return !isUndefined(val)
 }
 export function arrayBufferToBase64( buffer ) {
     let binary = '';
     let bytes = new Uint8Array( buffer );
     let len = bytes.byteLength;
     for (let i = 0; i < len; i++) {
         binary += String.fromCharCode( bytes[ i ] );
     }
     return window.btoa( binary );
 }

 export function objectTraverse(obj, opp, path) {
        if (isUndefined(path)) {
            path=""
        }
        opp(obj,path);
        if (obj.children && obj.children.length>0) {
            obj.children.forEach(function(child) {
                objectTraverse(child, opp, path+"_"+obj.id);
            })
        }
 }
