import {useTranslation} from "react-i18next";
import React from "react";

import {useDynamicThumbnailGUIContext} from "../DynamicThumbnailGUIContext";
import {stripHtml} from "../../../utils/Utils";


export const TextExtractor = (props: { contentFields: string }) => {
    const {display} = useDynamicThumbnailGUIContext()
    const {t} = useTranslation();
    let cFliedArr = props.contentFields.split(",");
    return (
        <div className={"p-3 display-preview-tile"}>
            {display.tpl.pages && display.tpl.pages[0].content.map((content: any, index: number) => {
                return <div key={display.id + "_" + index}>
                    {cFliedArr.map((field: string, idx: number) => {
                        return <div key={display.id+"_"+index+"_"+idx}
                                    className={"fnt-display-preview-tile fst-italic"}>{stripHtml(content[field])}</div>
                    })
                    }
                </div>
            })}
        </div>
    );
}