import React from "react";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";

export const CenterToggle = () => {
    const {t} = useTranslation();
    const {pageNumber, numOfPages} = useDynamicPageGUIContext();
    const {
        register,
        formState: {errors}
    } = useFormContext()

    return (
        <div className="mb-3">
            <Form.Check
                {...register(`tpl.pages.${pageNumber}.center`)}
                type={"checkbox"}
                id={"checkbox-center-" + pageNumber}
                label={t("centerText")}
            />
        </div>);
}