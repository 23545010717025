import {SubHeadline} from "../subheadline/SubHeadline";
import {ListGroup} from "react-bootstrap";
import React from "react";

import Form from 'react-bootstrap/Form';
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {DisplayGroupModel} from "../../models/DisplayGroupModel";
import {DisplayModel} from "../../models/DisplayModel";


export const FormIntegratedDisplayList = (props: {displays:DisplayModel[]}) => {
    const {t, i18n} = useTranslation();
    const { register } = useFormContext()
    return (
        <ListGroup>
            {props.displays && props.displays.map((display,index) => (
                <ListGroup.Item key={display.id} >
                    <Form.Check
                        {...register("displays."+index+".selected")}
                        label={display.name}
                    />
                </ListGroup.Item>
            ))}
        </ListGroup>

    );
}
