import React from "react";
import {useDynamicPageGUIContext} from "../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {CaretDownFill, CaretUpFill, PlusLg, TrashFill} from "react-bootstrap-icons";


export const PageOperations = () => {
    const {pageNumber,numOfPages,movePageDownFunc,
        movePageUpFunc, addPageFunc, removePageFunc} = useDynamicPageGUIContext();
    const {t} = useTranslation();

    return (<div className="d-flex">
        <div className="flex-grow-1"><h5
            className="my-0 fw-normal">{t("page")} {pageNumber + 1}</h5></div>
        <div>
            {numOfPages > 1 && pageNumber < numOfPages - 1 &&
                <CaretDownFill onClick={(e) => {
                    movePageDownFunc(pageNumber);
                }} title={t("moveDownPage")} className="me-3 pointer"/>}
            {numOfPages > 1 && pageNumber > 0 && <CaretUpFill onClick={(e) => {
                movePageUpFunc(pageNumber);
            }} title={t("moveUpPage")} className="me-3 pointer"/>}
            <PlusLg title={t("addPage")} onClick={(e) => {
                addPageFunc(pageNumber);
            }} className="me-3 pointer"/>
            {numOfPages > 1 && <TrashFill onClick={(e) => {
                removePageFunc(pageNumber);
            }} title={t("removePage")} className="me-1 pointer"/>}
        </div>
    </div>);
}