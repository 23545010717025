import React from "react";
import {SubHeadline} from "../../../components/subheadline/SubHeadline";
import {Col, Row} from "react-bootstrap";


export const DisplayPreviewer = () => {


    return <><SubHeadline>Preview</SubHeadline>
        <Row>
            <Col className="col-12">
                <div className="border border-primary p-2">
                    <div className="my-4 text-center">Previewdarstellung der Anzeige hier ist in
                        Arbeit...
                    </div>
                </div>
            </Col>
        </Row></>;
}