import React from "react";
import {useTranslation} from "react-i18next";
import {Template} from "./Template";


export const TemplateManager = (props: { showParameter:boolean, parameterGui:string, pageGui:string}) => {
    const {t} = useTranslation();



    return (<>
        <Template  showParameter={props.showParameter} parameterGui={props.parameterGui} pageGui={props.pageGui}/>
    </>);
}