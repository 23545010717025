import React, { createContext, useContext, useState, ReactNode } from 'react';
import {DisplayModel} from "../../models/DisplayModel";

interface DynamicThumbnailGUIContextType {
    display:DisplayModel
}

const DynamicThumbnailGUIContext = createContext<DynamicThumbnailGUIContextType | undefined>(undefined);

export const DynamicThumbnailGUIContextProvider: React.FC<{ display:DisplayModel, children: ReactNode }> = ({ display, children }) => {
    return (
        <DynamicThumbnailGUIContext.Provider value={{ display }}>
            {children}
        </DynamicThumbnailGUIContext.Provider>
    );
};


export const useDynamicThumbnailGUIContext = (): DynamicThumbnailGUIContextType => {
    const context = useContext(DynamicThumbnailGUIContext);
    if (!context) {
        throw new Error('useDynamicThumbnailGUIContext must be used within a DynamicThumbnailGUIContextProvider');
    }
    return context;
};