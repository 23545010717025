import React from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import {DynamicParameterGUI} from "./parameterGui/DynamicParameterGUI";
import {DynamicParameterGUIContextProvider} from "./parameterGui/DynamicParameterGUIContext";


export const TemplateParameter = (props: {  gui: string })=> {
    const {register, control,
        getValues, formState: {errors}} = useFormContext()

    const {fields, append, remove} = useFieldArray({
        name: "tpl.parameter.opticalLineSplitting",
        control
    });

    const updateNumLines = () => {
        const numOfLines = parseInt(getValues("tpl.parameter.numLines"));
        const currentNumOfLines = fields.length;

        if (currentNumOfLines < numOfLines) {
            for (let i = currentNumOfLines; i < numOfLines; i++) {
                append({ splitAt: "1" });
            }
        } else if (currentNumOfLines > numOfLines) {
            for (let i = currentNumOfLines; i > numOfLines; i--) {
                remove(i - 1);
            }
        }
    };

    return (
        <DynamicParameterGUIContextProvider fields={fields} updateNumLines={updateNumLines}>
            <DynamicParameterGUI gui={props.gui}/>
        </DynamicParameterGUIContextProvider>
    );
}