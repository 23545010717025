import {Col,  Row} from "react-bootstrap";
import React from "react";
import {DynamicPageGUIContextProvider} from "./pageGui/DynamicPageGUIContext";
import {DynamicPageGUI} from "./pageGui/DynamicPageGUI";

export const TemplatePage = (props: {
    gui:string
    pageNumber: number
    addPageFunc: any
    removePageFunc: any
    movePageUpFunc: any
    movePageDownFunc: any
    numOfPages: number
}) => {

    return (
        <Row>
            <Col>
                <DynamicPageGUIContextProvider movePageDownFunc={props.movePageDownFunc}
                                               movePageUpFunc={props.movePageUpFunc}
                                               addPageFunc={props.addPageFunc}
                                               removePageFunc={props.removePageFunc}
                                               numOfPages={props.numOfPages} pageNumber={props.pageNumber}>
                    <DynamicPageGUI gui={props.gui}/>
                </DynamicPageGUIContextProvider>
            </Col>
        </Row>
    );
}