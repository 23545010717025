import React, {useState} from 'react';
import logo from '../../schauf_logo_breit.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Copyright} from '../../components/copyright/Copyright';
import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useQuery} from "../../hooks/useQueryHook";
import {useLoaderData} from "react-router";
import {TokenCheckResultModel} from "../../models/TokenCheckResultModel";
import axios from "axios";

export const ForgottenPasswordReset = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [saved, setSaved] = useState(false);
    let query = useQuery();

    type FormValues = {
        password: string
        passwordRepeat: string
    }

    type ResetPasswordRequest = {
        password: string
        passwordRepeat: string
        token: string
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isSubmitting},
    } = useForm<FormValues>({
        resolver: yupResolver(yup.object({
            password: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("password")})).min(8, t("error.validation.SizeMin", {min: "8"})),
            passwordRepeat: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("passwordRepeat")})).oneOf([yup.ref("password")],
                t("error.validation.passwordsMustMatch"))
        }))
    });

    const tokenCheckResult = useLoaderData() as TokenCheckResultModel;
    const handleBackToLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate('/login');
    }

    const onSubmit = async (values: FormValues) => {
        let pwResetRequest: ResetPasswordRequest = {
            password: values.password,
            passwordRepeat: values.passwordRepeat,
            token: tokenCheckResult.token
        }

        await axios.post("/api/v2/password/setpw",
            pwResetRequest)
            .then(function (response) {
                console.log(response)
                setSaved(true);
            })
            .catch(function (error) {
                setError("passwordRepeat",
                    {message: t("forgottenPasswordSaveNotPossible")});
                setSaved(false);
            });
    };

    const onError = (error: any) => {
        console.log("ERROR:::", error);
    };

    return (
        <>
            {tokenCheckResult.valid &&
                <div className="slim-form text-center h-100">
                    <div className="form-signin w-100 m-auto">
                        <a href="/dashboard"><img className="mb-4" src={logo} alt="" width="175"/></a>
                        <h1 className={saved ? 'd-none' : 'h3 mb-3 fw-normal'}>{t("setNewPassword")}</h1>
                        <div className={saved ? 'd-none' : ""}>
                            <Form onSubmit={handleSubmit(onSubmit, onError)}>
                                <Form.Group className="form-floating">
                                    <Form.Control type="password"  {...register("password")}
                                                  className="form-control rounded-0"
                                                  isInvalid={!!errors.password?.message}/>
                                    <Form.Control.Feedback
                                        type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                    <Form.Label>{t("password")}</Form.Label>
                                </Form.Group>
                                <Form.Group className="form-floating">
                                    <Form.Control type="password"  {...register("passwordRepeat")}
                                                  className="form-control rounded-0"
                                                  isInvalid={!!errors.passwordRepeat?.message}/>
                                    <Form.Control.Feedback
                                        type="invalid">{errors.passwordRepeat?.message}</Form.Control.Feedback>
                                    <Form.Label>{t("password")}</Form.Label>
                                </Form.Group>
                                <Button disabled={isSubmitting} type="submit" className="mt-4 w-100 btn btn-lg"
                                        variant="primary">{t("submit")}</Button>
                            </Form>
                        </div>

                        <div className={!saved ? 'd-none' : ''}>
                            <Form>
                                <div>{t("passwordSuccessfullyChanged")}</div>
                                <Button type="submit" className="mt-4 w-100 btn btn-lg"
                                        variant="primary" onClick={handleBackToLogin}>{t("toLogin")}</Button>
                            </Form>
                        </div>
                        <div className="mt-4 mb-3 text-body-secondary"><Copyright/></div>
                    </div>
                </div>}
            {!tokenCheckResult.valid && <Form>
                <div className="slim-form text-center h-100">
                    <div className="form-signin w-100 m-auto">
                        <a href="/dashboard"><img className="mb-4" src={logo} alt="" width="150"/></a>
                        <h1 className="h3 mb-3 fw-normal">{t("tokenInvalid")}</h1>
                        <div>
                        {t("tokenInvalidOrUsed")}
                        <Button className="mt-4 w-100 btn btn-lg"
                                variant="primary" onClick={handleBackToLogin}>{t("toLogin")}</Button></div></div>
                </div>
            </Form>}
        </>);
}


export async function loader(obj: any) {
    return await axios.get("/api/v2/tokens/" + obj.params.token)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw new Error(error.response);
        });
}