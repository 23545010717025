import Alert from "react-bootstrap/Alert";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";



export const SimpleFormStatusAlert = () => {
    const {t} = useTranslation();
    const {formState: {errors,isSubmitSuccessful,isDirty} } = useFormContext()
    // console.log(errors.root);
    // console.log("isSubmitSuccessful",isSubmitSuccessful);
    // console.log("isDirty",isDirty);

    return (<Alert show={errors.root !== undefined || (isSubmitSuccessful && !isDirty)}
                    variant={errors.root !== undefined ? "danger" : "success"}>
        {errors.root !== undefined && errors.root?.message}
        {isSubmitSuccessful && errors.root === undefined && t("successfullySaved")}
    </Alert>);
}


