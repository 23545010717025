import {AppSettings} from "../models/AppSettings";
import AppConfig from "../App";

export namespace ConfigUtils {

    export function update(appConfig: AppSettings) {
        AppConfig.updated = true;
        AppConfig.emailCommunication = appConfig.emailCommunication;
    }

}