import React from "react";
import PropTypes from "prop-types";

const DataColumn= (props:{label?:string, field?:string, renderer?:any, width?:string}) => <></>;

DataColumn.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

DataColumn.defaultProps = {
    __TYPE: 'DataColumn',
};


export default DataColumn;