
import {DisplayModel} from "../../../models/DisplayModel";
import {Accordion} from "react-bootstrap";

import {DashboardInlineTempleManager} from "./DashboardInlineTempleManager";

export const DashboardDisplayItem = (props: { displayGroupId:number, display: DisplayModel,setActiveKey:any,activeKey:any,setReload:any}) => {
    return (
        <Accordion.Item eventKey={String(props.display.id)} onClick={
            () => {
                if (props.activeKey===String(props.display.id)) {
                    props.setActiveKey(null);
                } else {
                    props.setActiveKey(String(props.display.id));
                }
            }
        }>
            <Accordion.Header>{props.display.name}</Accordion.Header>
            <Accordion.Body  onClick={(e) => {
                e.stopPropagation();
            }}>
                <DashboardInlineTempleManager displayGroupId={props.displayGroupId} display={props.display} setShow={null}  setActiveKey={props.setActiveKey} setReload={props.setReload}/>
            </Accordion.Body>
        </Accordion.Item>)
}