import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {NumberOfLinesSelector} from "./elements/NumberOfLinesSelector";
import {NumberOfCharsPerLineSelector} from "./elements/NumberOfCharsPerLineSelector";
import {OpticalLineSplitting} from "./elements/OpticalLineSplitting";

import {DynamicGUIProps, parseXMLToReact, XMLNode} from "../common/Xml2ReactTransformer";
import {Div} from "../common/elements/Div";
import {H5} from "../common/elements/H5";
import {RowDistance} from "./elements/RowDistance";
import {ColorSettings} from "./elements/ColorSettings";
import {DefaultParameter} from "./elements/DefaultParameter";



export const DynamicParameterGUI: React.FC<DynamicGUIProps> = ({ gui}) => {

    const componentMap: { [key: string]: React.FC<any> } = {
        'Row': Row,
        'Col': Col,
        "Div": Div,
        "H5":H5,
        "NumberOfLinesSelector":NumberOfLinesSelector,
        "NumberOfCharsPerLineSelector":NumberOfCharsPerLineSelector,
        "OpticalLineSplitting":OpticalLineSplitting,
        "RowDistance":RowDistance,
        "ColorSettings":ColorSettings,
        "DefaultParameter":DefaultParameter
    };

    const {t} = useTranslation();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(gui, "text/xml");
    const rootElement = xmlDoc.documentElement as unknown as XMLNode;
    return parseXMLToReact(rootElement,0,t,componentMap);
};
