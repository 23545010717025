import { Navigate, Outlet } from "react-router-dom"
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {IUserData} from "../../models/IUserData";
import {UserUtils} from "../../utils/UserUtils";

export const ProtectedRoute = (props : {allowedRoles:string, redirectTo:string, children:any}) => {

    const auth = useAuthUser<IUserData>()

    if (!UserUtils.isAdmin(auth)) {
        return <Navigate to={props.redirectTo} />
    }
    return props.children ? props.children : <Outlet />
}


