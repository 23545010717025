import React from "react";
import {ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import Button from "react-bootstrap/Button";
import {EraserFill, LightningChargeFill, TypeBold} from "react-bootstrap-icons";
import {SelectionUtils} from "../../../../utils/SelectionUtils";

const Divider = () => {
    return <div className="border btn-group-divider"/>;
};

export const RtToolbar = (props: { setToolbarButtonClickData:any, toolbarButtonClickData:any,hasMultiColor: boolean, hasBold: boolean, hasFlash: boolean }) => {
    const {t} = useTranslation();
    const {
        formState: {errors}
    } = useFormContext()

    return (<>{(props.hasFlash || props.hasBold || props.hasMultiColor) && <ButtonToolbar className={"mb-2"}>
        <ButtonGroup className="me-2">
            <Button variant="secondary" onClick={
                (e) => {
                    e.preventDefault();
                 //    SelectionUtils.stripHtmlFromSelection();
                    SelectionUtils.removeFormat();
                    props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'removeFormat'});
                }
            } title={t("removeFormat")}>
                <EraserFill/>
            </Button>
            <Divider/>
            {props.hasFlash && <Button variant="secondary" onClick={
                (e) => {
                    e.preventDefault();
                    SelectionUtils.wrapSelectedText("rte-text-flash");
                    props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'flash'});
                }
            } title={t("flashingContent")}>
                <LightningChargeFill/>
            </Button>}
            {props.hasBold && <Button variant="secondary" title={t("wideText")} className="pointer" onClick={
                (e) => {
                    e.preventDefault();
                    SelectionUtils.wrapSelectedText("rte-font-wide");
                    props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'wideText'});
                }}><TypeBold/></Button>}
            {props.hasMultiColor && <>
                <Button variant="secondary" title={t("textRed")} onClick={
                    (e) => {
                        e.preventDefault();
                        SelectionUtils.wrapSelectedText("rte-text-red");
                        props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'red'});
                    }}>
                    <div style={{width: "20px", height: "20px", backgroundColor: "red"}}/>
                </Button>
                <Button variant="secondary" title={t("textYellow")} onClick={
                    (e) => {
                        e.preventDefault();
                        SelectionUtils.wrapSelectedText("rte-text-yellow");
                        props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'yellow'});
                    }}>
                    <div style={{width: "20px", height: "20px", backgroundColor: "yellow"}}/>
                </Button>
                <Button variant="secondary" title={t("textGreen")} onClick={
                    (e) => {
                        e.preventDefault();
                        SelectionUtils.wrapSelectedText("rte-text-green");
                        props.setToolbarButtonClickData({...props.toolbarButtonClickData, name: 'green'});
                    }}>
                    <div style={{width: "20px", height: "20px", backgroundColor: "green"}}/>
                </Button>
            </>}
        </ButtonGroup>
    </ButtonToolbar>} </>);
}