import {Headline} from '../../components/headline/Headline';
import { useTranslation } from 'react-i18next';
import {Container} from "react-bootstrap";
import DataTable from "../../2ndlib/components/databable/DataTable";
import DataColumn from "../../2ndlib/components/databable/DataColumn";
import EditInfo from "../../2ndlib/components/databable/EditInfo";
import DeleteInfo from "../../2ndlib/components/databable/DeleteInfo";
import AddInfo from "../../2ndlib/components/databable/AddInfo";
import Button from "react-bootstrap/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {DisplayModel} from "../../models/DisplayModel";
import {ArrowLeft} from "react-bootstrap-icons";
import DuplicateInfo from "../../2ndlib/components/databable/DuplicateInfo";


export const DisplayList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const ipPortRenderer = (row: DisplayModel) => {
        return <>{row.ip+":"+row.port}</>;
    }

    const displayGroupRenderer = (row: any) => {
        return <i>{row.displayGroups?.map((displayGroup:any) => displayGroup.name).join(', ')}</i>;
    }

    return (
        <main className="px-3">
            <Container>
                <Headline>{t("displays")}</Headline>
                <div className={"mt-3"}>
                    <DataTable dataUrl={"/api/v2/displays?withDisplayGroups=true"}   idField={"id"} noDataMessage={t("noDataFound")}>
                        <DataColumn label={t("name")} field="name" />
                        <DataColumn label={t("networkAddress")} renderer={ipPortRenderer} />
                        <DataColumn label={t("assignedDisplayGroups")} renderer={displayGroupRenderer} />
                        <EditInfo view="/app/display/{id}" title={t("editDisplay")}/>
                        <DeleteInfo serviceUrl="/api/displays/{id}" title={t("deleteDisplay")}
                                    text={t("sureToDeleteDisplay")}/>
                        <AddInfo view="/app/display/template/select" title={t("newDisplay")}/>
                        <DuplicateInfo serviceUrl="/api/v2/displays/{id}/duplicate"/>
                    </DataTable>
                </div>
                <Button className="me-2" variant="outline-secondary" onClick={e => {
                    navigate("/app/dashboard");
                }}><ArrowLeft className="pointer"/></Button>
                <Button className="me-2" type="button" onClick={(e) => {
                    navigate("/app/display/template/select");
                }} variant="outline-secondary">{t("newDisplay")}</Button>
            </Container>
        </main>
    );
}

