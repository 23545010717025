import React from 'react';
import {ContentEditableWithCounter} from "./ContentEditableWithCounter";
import {useFormContext, useWatch} from "react-hook-form";
import Form from "react-bootstrap/Form";

export const PairedContentEditableWithCounter = (props: {
    flashTextVisible:boolean, pageNumber:number, idex: number, charLimit: number,
    toolbarButtonClickData:any }) => {

    const {
        register,
        control,
        formState: {errors}
    } = useFormContext()

    const wallSwitch = 1;
    const name1 = `tpl.pages.${props.pageNumber}.content.${props.idex}.text1`;
    const name2 = `tpl.pages.${props.pageNumber}.content.${props.idex}.text2`;
    const name3 = `tpl.pages.${props.pageNumber}.content.${props.idex}.merge`;

    const templateParameter = useWatch({
        control,
        name: "tpl.parameter"
    });

    const isMerge = useWatch({
        control,
        name: name3
    });

    let dVersion = 1
    if (templateParameter.version) {
        dVersion = parseInt(templateParameter.version)
    }

    return (
        <div className={"mt-1"}>
            {((dVersion === 1) || (dVersion === 2 && props.idex >= wallSwitch)) && <>
                <ContentEditableWithCounter  toolbarButtonClickData={props.toolbarButtonClickData} flashTextVisible={props.flashTextVisible} name={name1} clsName={"led-text led-text-red"} charLimit={props.charLimit}/>
                <ContentEditableWithCounter toolbarButtonClickData={props.toolbarButtonClickData} flashTextVisible={props.flashTextVisible} name={name2} clsName={"led-text led-text-red"} charLimit={props.charLimit}/>
            </>}
            {dVersion === 2 && props.idex < wallSwitch &&
                <div className="d-flex">
                    <div className="flex-grow-1">
                        {isMerge && <ContentEditableWithCounter toolbarButtonClickData={props.toolbarButtonClickData} flashTextVisible={props.flashTextVisible} clsName={"pt-3 pb-2 led-text led-text-large led-text-red"} name={name1} charLimit={props.charLimit}/>}
                        {!isMerge && <><ContentEditableWithCounter toolbarButtonClickData={props.toolbarButtonClickData} flashTextVisible={props.flashTextVisible} clsName={"led-text led-text-red"} name={name1} charLimit={props.charLimit}/>
                        <ContentEditableWithCounter toolbarButtonClickData={props.toolbarButtonClickData} name={name2} flashTextVisible={props.flashTextVisible} clsName={"led-text led-text-red"} charLimit={props.charLimit}/></>}
                    </div>
                    <div className={"ps-2"}>
                        <Form.Check
                            {...register(name3)}
                            type={"checkbox"}
                        />
                    </div>
                </div>}
        </div>
    );
};
