import React from "react";
import {useTranslation} from "react-i18next";
import {ButtonGroup, ToggleButton} from "react-bootstrap";
import {Grid3x2, Grid3x3, List} from "react-bootstrap-icons";


export const DisplayListTypeChooser = (props:{listViewMode:any,setListViewMode:any}) => {

    return (
        <ButtonGroup className="mb-2">
            {[
                { value: 'list' },
                { value: 'tiles' }
            ].map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={radio.value}
                    checked={props.listViewMode === radio.value}
                    onChange={(e) => props.setListViewMode(e.currentTarget.value)}
                >
                    {radio.value==="tiles" && <Grid3x2/>}
                    {radio.value==="list" && <List/>}
                </ToggleButton>
            ))}
        </ButtonGroup>
    );
}