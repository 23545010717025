import {SubHeadline} from "../subheadline/SubHeadline";
import {Col, ListGroup, Row} from "react-bootstrap";
import React from "react";

import Form from 'react-bootstrap/Form';
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {DisplayGroupModel} from "../../models/DisplayGroupModel";


export const FormIntegratedDisplayGroupList = (props: {displayGroups:DisplayGroupModel[], showDisplays:boolean}) => {
    const {t} = useTranslation();
    const { register } = useFormContext()
    return (
        <ListGroup>
            {props.displayGroups.map((displayGroup,index) => (
                <ListGroup.Item key={displayGroup.id} >
                    <Row>
                        <Col className="col-12 col-md-4">
                        <Form.Check
                        {...register("displayGroups."+index+".selected")}
                        label={displayGroup.name}
                        /></Col>


                        {props.showDisplays && <Col>{displayGroup.displays?.map(display => display.name).join(', ')}</Col>}

                    </Row>


                </ListGroup.Item>
            ))}
        </ListGroup>);
}
