
import React from "react";
import PropTypes from "prop-types";

const EditInfo= (props:{view:string, title:string}) => <></>;

EditInfo.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

EditInfo.defaultProps = {
    __TYPE: 'EditInfo',
};


export default EditInfo;