import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import React from "react";
import {SubHeadline} from "../../../components/subheadline/SubHeadline";
import {Col,  Row} from "react-bootstrap";
import {useDynamicParameterGUIContext} from "../DynamicParameterGUIContext";

export const ColorSettings = () => {
    const {register, watch, control, getValues, formState: {errors}} = useFormContext()
    const {fields} = useDynamicParameterGUIContext()
    const {t} = useTranslation();
    return (
        <>
            <SubHeadline>{t("colorSettings")}</SubHeadline>
<Row><Col>
                <Form.Group className="mb-3">
                    <Form.Label>{t("bgColorBeforeSplit")}</Form.Label>
                    <Form.Control type={"color"}  {...register("tpl.parameter.bgColorS1")}
                                  className="form-control rounded-0" defaultValue="#000000"/>
                </Form.Group>
</Col><Col>
    <Form.Group className="mb-3">
        <Form.Label>{t("colorBeforeSplit")}</Form.Label>
        <Form.Control type={"color"}  {...register("tpl.parameter.colorS1")}
                      className="form-control rounded-0" defaultValue="#ffffff"/>
    </Form.Group>
</Col></Row><Row><Col>
            <Form.Group className="mb-3">
                <Form.Label>{t("bgColorAfterSplit")}</Form.Label>
                <Form.Control type={"color"}  {...register("tpl.parameter.bgColorS2")}
                              className="form-control rounded-0" defaultValue="#000000"/>
            </Form.Group>
        </Col><Col>
            <Form.Group className="mb-3">
                <Form.Label>{t("colorAfterSplit")}</Form.Label>
                <Form.Control type={"color"}  {...register("tpl.parameter.colorS2")}
                              className="form-control rounded-0" defaultValue="#fefb53"/>
            </Form.Group></Col></Row>
        </>
    );
}