import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {TemplateModel} from "../../models/TemplateModel";
import {Headline} from "../../components/headline/Headline";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {TemplateSelect} from "../../components/templateSelectorModal/TemplateSelect";
import {ArrowLeft} from "react-bootstrap-icons";


export const DisplayTemplateSelection = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [selectedTemplate, setSelectedTemplate]
        = useState<TemplateModel | null >(null);

    return (
        <main className="px-3">
            <Container>
                <Headline>{t("createNewDisplay")}</Headline>
                <div className={"mt-3"}>
                    <div>
                        {t("selectTemplateForNewDisplay")}
                    </div>
                    <div className={"mt-2"}>
                       <TemplateSelect setSelectedTemplate={setSelectedTemplate}/>
                    </div>
                    <div className={"mt-3"}>
                        <Button className="me-2" variant="outline-secondary" onClick={e => {
                            navigate("/app/displays");
                        }}><ArrowLeft className="pointer"/></Button>
                        <Button disabled={selectedTemplate===null} className="me-2" type="button" onClick={(e) => {
                           if (selectedTemplate!==null) {
                               navigate(`/app/display/0?t=${selectedTemplate.id}`);
                           }
                        }} variant="primary">{t("next")}</Button>
                    </div>
                </div>
            </Container>
        </main>
    );
}


