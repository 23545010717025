
import {UseFormSetError} from "react-hook-form/dist/types/form";
import axiosInstance from "../api/axios";

export class Utils {

    public static generatePass(length: number): string {
        let pass = '';
        let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
            'abcdefghijklmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= length; i++) {
            let char = Math.floor(Math.random()
                * str.length + 1);
            pass += str.charAt(char)
        }

        return pass;

    }

    public static async simpleSubmitHandler(
        values: any,
        setError: UseFormSetError<any>, t: any, url: string) {
        return new Promise((resolve, reject) => {
            let id = values.id;
            if (url.includes("{id}")) {
                url = url.replace("{id}", ((id !== undefined && id!==null) ? (id) : ""));
                if (url.endsWith("/")) {
                    url = url.substring(0, url.length - 1);
                }
            } else {
                url = url + ((id !== undefined && id!==null) ? ("/" + id) : "")
            }

            axiosInstance.request({
                url: url,
                method: (id !== undefined && id!==null) ? "PUT" : "POST",
                data: values
            },)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if ((error !== undefined) && (error.response !== undefined)) {
                        let dta = error.response.data;
                       // console.log(error);
                        setError(dta.items[0].field,
                            {
                                message: t(dta.items[0].i18nKey,
                                    {fieldName: t(dta.items[0].field)})
                            })
                    } else {
                        setError("root",
                            {
                                message: t("error.validation.unknown")
                            })
                    }
                    reject(error);
                });
        });
    }


    public static  getCookie(name:string) {
        let cookieArr = document.cookie.split(";");
        for(let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");
            // Remove whitespace at the beginning of the cookie name and compare it with the given string
            if(name === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
        // Return null if not found
        return null;
    }



}