import React from "react";
import {useFormContext} from "react-hook-form";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";


export const NumberOfCharsPerLineSelector = (props:{}) => {
    const {register, watch, control, getValues, formState: {errors}} = useFormContext()
    const {t} = useTranslation();
    return (
        <Form.Group className="mb-3">
            <Form.Label>{t("numCharsPerLine")}</Form.Label>
            <Form.Select {...register("tpl.parameter.numCharsPerLine")}>
                {Array.from({length: 50}, (_, i) => <option key={"noc"+i} value={i + 1}>{i + 1}</option>)}
            </Form.Select>
        </Form.Group>
    );
}