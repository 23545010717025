import React, {createContext, useContext, ReactNode} from 'react';

interface DynamicPageGUIContextType {
    numOfPages: number
    pageNumber: number
    movePageDownFunc: any
    movePageUpFunc: any
    addPageFunc: any
    removePageFunc: any
}

const DynamicPageGUIContext = createContext<DynamicPageGUIContextType | undefined>(undefined);

export const DynamicPageGUIContextProvider: React.FC<{
    numOfPages: number
    pageNumber: number
    movePageDownFunc: any
    movePageUpFunc:any
    addPageFunc:any
    removePageFunc:any
    children: ReactNode
}> = ({numOfPages, pageNumber,movePageDownFunc, movePageUpFunc, addPageFunc, removePageFunc,children}) => {
    return (
        <DynamicPageGUIContext.Provider value={{numOfPages, pageNumber,movePageDownFunc, movePageUpFunc, addPageFunc, removePageFunc}}>
            {children}
        </DynamicPageGUIContext.Provider>
    );
};


export const useDynamicPageGUIContext = (): DynamicPageGUIContextType => {
    const context = useContext(DynamicPageGUIContext);
    if (!context) {
        throw new Error('useDynamicPageGUIContext must be used within a DynamicPageGUIContextProvider');
    }
    return context;
};