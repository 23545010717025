import {Headline} from '../../components/headline/Headline';
import {useTranslation} from 'react-i18next';
import {ButtonGroup, Col, Container, Row, ToggleButton} from "react-bootstrap";
import {useLoaderData} from "react-router";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import axiosInstance from "../../2ndlib/api/axios";
import {DashboardDisplayList} from "../../components/displayList/DashboardDisplayList";
import {DisplayGroupModel} from "../../models/DisplayGroupModel";
import {DisplayListTypeChooser} from "./DisplayListTypeChooser";
import {DashboardDisplayTiles} from "../../components/displayList/DashboardDisplayTiles";
import {uid} from "../../utils/Utils";

export const Dashboard = () => {
    const {t} = useTranslation();
    const [selectedDisplayGroupId, setSelectedDisplayGroupId] = useState<number | undefined | null>(null);
    const [reload, setReload] = useState<string>(uid());

    const [displayGroup, setDisplayGroup] = useState<DisplayGroupModel | null>(null);
    const displayGroups = useLoaderData() as DisplayGroupModel[];
    const [listViewMode, setListViewMode] = useState("list");


    useEffect(() => {
        if (displayGroups && displayGroups.length > 0) {
            if (selectedDisplayGroupId) {
                const fetchData = async () => {
                    try {
                        const response = await axiosInstance.get(`/api/v2/displayGroups/${selectedDisplayGroupId}?widthGuiCodes=true`);
                        let displayGroup = response.data;
                        displayGroup.displays = displayGroup.displays.map((display: any) => {
                            display.tpl = JSON.parse(display.tpl);
                            return display;
                        });
                        setDisplayGroup(displayGroup);
                    } catch (error) {
                        console.error(error);
                    }
                };
                fetchData();
            } else {
                setSelectedDisplayGroupId(displayGroups[0].id);
            }
            return;
        }
    }, [selectedDisplayGroupId,reload]);

    return (
        <main className="px-3">
            <Container>
                <Headline>{t('dashboard')}</Headline>
                <div className="mb-3">


                    {displayGroups && displayGroups.length > 0 &&
                        <>
                            <Row>
                                <Col xs={11}>
                                    <Form.Select onChange={(e) => {
                                        setSelectedDisplayGroupId(parseInt(e.target.value))
                                    }}>
                                        {displayGroups.map(displayGroup => (
                                            displayGroup.id &&
                                            <option key={displayGroup.id}
                                                    value={displayGroup.id}>{displayGroup.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col xs={1}>
                                    <DisplayListTypeChooser listViewMode={listViewMode}
                                                            setListViewMode={setListViewMode}/>
                                </Col>
                            </Row>
                            {listViewMode === "list" && displayGroup && displayGroup.displays && displayGroup.displays.length > 0 &&
                                <div className={"mt-3"}><DashboardDisplayList setReload={setReload} displayGroup={displayGroup}/></div>
                            }

                            {listViewMode === "tiles" && displayGroup && displayGroup.displays && displayGroup.displays.length > 0 &&
                                <DashboardDisplayTiles setReload={setReload} displayGroup={displayGroup}/>
                            }

                            {(!displayGroup || !displayGroup.displays || displayGroup.displays.length === 0) &&
                                <div className={"mt-3"}> {t("noDisplaysForSelectedDisplayGroup")}</div>
                            }
                        </>
                    }
                    {(!displayGroups || displayGroups.length === 0) &&
                        <>
                            {t("noDisplayGroupsAvailable")}
                        </>}
                </div>
            </Container>


        </main>
    );
}

export async function loader(obj: any) {
    return await axiosInstance.get("/api/v2/displayGroups?withDisplays=false")
        .then(function (response: AxiosResponse<any, any>) {
            return response.data;
        })
        .catch(function (error: any) {
            throw new Error(error.response);
        });
}

