import React, {useState} from "react";
import logo from "../../schauf_logo.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {Copyright} from "../../components/copyright/Copyright";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

// https://www.youtube.com/watch?v=cc_xmawJ8Kg

export const Register = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false)
        navigate("/login");
    };

    type FormValues = {
        salutation: string
        firstname: string
        lastname: string
        email: string
        password: string
        passwordRepeat: string
    }

    const {
        // zod oder yup
        register,
        handleSubmit,
        setError,
        formState: {errors,isSubmitting},
    } = useForm<FormValues>({
        resolver: yupResolver(yup.object({
            salutation: yup.string().trim().required(),
            firstname: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("firstname")})),
            lastname: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("lastname")})),
            email: yup.string().email(t("error.validation.Email")).trim().required(t("error.validation.NotBlank", {fieldName: t("eMailAddress")})),
            password: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("password")})).min(8, t("error.validation.SizeMin",{min: "8"})),
            passwordRepeat: yup.string().trim().required(t("error.validation.NotBlank", {fieldName: t("passwordRepeat")})).oneOf([yup.ref("password")],
                t("error.validation.passwordsMustMatch"))
        })),
        defaultValues: {
            salutation: "female",
        }
    });

    const onSubmit = async (values:FormValues) => {
       await axios.post("/api/v2/register/user", values)
            .then(function (response) {
                setShowModal(true);
            })
            .catch(function (error) {
                setError(error.response.data.items[0].field,
                    {message:t(error.response.data.items[0].i18nKey,
                            {fieldName: t(error.response.data.items[0].field)})});
            });
    };

    const onError = (error:any) => {
        console.log("ERROR:::", error);
    };

    return (
        <div className="slim-form text-center h-100">
            <div className="form-register w-100 m-auto">
                <img onClick={() => {
                    navigate("/login")
                }} className="pointer mb-4" src={logo} alt="" width="150"/>
                <h1 className="h3 mb-3 fw-normal">{t("registration")}</h1>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Form.Group className="form-floating">
                        <Form.Select className="rounded-0 rounded-top" {...register("salutation")}>
                            <option value="female">{t("salutationMrs")}</option>
                            <option value="male">{t("salutationMr")}</option>
                            <option value="none">{t("salutationNone")}</option>
                        </Form.Select>
                        <Form.Label>{t("salutation")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating">
                        <Form.Control type="text"  {...register("firstname")}
                                      className="form-control rounded-0" isInvalid={!!errors.firstname?.message}/>
                        <Form.Control.Feedback type="invalid">{errors.firstname?.message}</Form.Control.Feedback>
                        <Form.Label>{t("firstname")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating">
                        <Form.Control type="text" {...register("lastname")}
                                      className="form-control rounded-0" isInvalid={!!errors.lastname?.message}/>
                        <Form.Control.Feedback type="invalid">{errors.lastname?.message}</Form.Control.Feedback>
                        <Form.Label>{t("lastname")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating">
                        <Form.Control type="text"  {...register("email")}
                                      className="form-control rounded-0" isInvalid={!!errors.email?.message}/>
                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        <Form.Label>{t("eMailAddress")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating">
                        <Form.Control type="password"
                                      {...register("password")}
                                      className="form-control rounded-0" isInvalid={!!errors.password?.message}/>
                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                        <Form.Label>{t("password")}</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating">
                        <Form.Control type="password"
                                      {...register("passwordRepeat")}
                                      className="rounded-0 rounded-bottom"
                                      isInvalid={!!errors.passwordRepeat?.message}/>
                        <Form.Control.Feedback
                            type="invalid">{errors.passwordRepeat?.message}</Form.Control.Feedback>
                        <Form.Label>{t("passwordRepeat")}</Form.Label>
                    </Form.Group>

                    <Button disabled={isSubmitting} type="submit" className="mt-4 w-100 btn btn-lg"
                            variant="primary">{t("submit")}</Button>
                    {errors.root && <Alert  variant="danger">
                        {errors.root.message}
                    </Alert>}
                    <Button type="button" onClick={(e) => {
                        navigate("/login")
                    }} className="mt-2 w-100 btn btn-lg"
                            variant="secondary">{t("abort")}</Button>
                </Form>

                <Modal show={showModal} backdrop="static" keyboard={false} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t("congratulationsThatWorked")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("registrationFinished")}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            {t("goToLogin")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="mt-4 mb-4 text-body-secondary"><Copyright/></div>
            </div>
        </div>
    );
}

