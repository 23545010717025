import {Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {EffectsSelector} from "./rtEditor/EffectsSelector";
import {FeatureSelector} from "./rtEditor/FeatureSelector";
import {CenterToggle} from "./rtEditor/CenterToggle";
import {TimeRange} from "./rtEditor/TimeRage";
import {EditAreaAndToolbar} from "./rtEditor/EditAreaAndToolbar";

export const RtEditor = () => {

    return (
        <>
            <Row>
                <Col xs={12} md={6}><EffectsSelector/></Col>
                <Col xs={12} md={6}><FeatureSelector/></Col>
            </Row>
            <TimeRange/>
            <CenterToggle/>
            <EditAreaAndToolbar/>
        </>
    );
}