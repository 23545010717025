import {Headline} from '../../components/headline/Headline';
import {useTranslation} from 'react-i18next';
import {Container} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ArrowLeft} from "react-bootstrap-icons";
import {LazyLog} from "@melloware/react-logviewer";

export const LogView = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <main className="px-3">
            <Container>
                <Headline>{t('logView')}</Headline>
                <div className="lead fw-normal">
                    <LazyLog url={process.env.REACT_APP_BACKEND_LOG_URL} stream enableMultilineHighlight enableHotKeys selectableLines caseInsensitive enableSearchNavigation enableSearch height={750}/>
                    <div className={"mt-3"}>
                        <Button className="me-2" variant="outline-secondary" onClick={e => {
                            navigate("/app/dashboard");
                        }}><ArrowLeft className="pointer"/></Button>
                    </div>
                </div>
            </Container>
        </main>
    );
}
