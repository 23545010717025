import React from "react";
import Form from "react-bootstrap/Form";
import {useDynamicPageGUIContext} from "../DynamicPageGUIContext";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";


export const PageDurationSelector = () => {
    const {t} = useTranslation();
    const {pageNumber, numOfPages} = useDynamicPageGUIContext();
    const {register, watch, control, getValues, formState: {errors}} = useFormContext()

    const cnt = numOfPages > 1 ? <div className="mt-3 mb-3">
        {t("duration")}
        <div className="mb-3">
            <Form.Select {...register(`tpl.pages.${pageNumber}.duration`)}>
                <option value="2">2 {t("seconds")}</option>
                <option value="5">5 {t("seconds")}</option>
                <option value="10">10 {t("seconds")}</option>
                <option value="20">20 {t("seconds")}</option>
                <option value="30">30 {t("seconds")}</option>
                <option value="45">45 {t("seconds")}</option>
                <option value="60">60 {t("seconds")}</option>
                <option value="90">90 {t("seconds")}</option>
                <option value="120">120 {t("seconds")}</option>
            </Form.Select>
        </div>
    </div> : <></>

    return (cnt)

}