import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";


import {DynamicGUIProps, parseXMLToReact, XMLNode} from "../common/Xml2ReactTransformer";
import {H5} from "../common/elements/H5";
import {Div} from "../common/elements/Div";
import {DisplayPreviewer} from "./elements/DisplayPreviewer";
import {MultiLine} from "./elements/MultiLine";
import {PageOperations} from "./elements/PageOperations";
import {PageDurationSelector} from "./elements/PageDurationSelector";
import {RtEditor} from "./elements/RtEditor";
import {MultiLineSplittableForm} from "./elements/MultiLineSplittableForm";
import {MultiLineSplittableForm2} from "./elements/MultiLineSplittableForm2";

export const DynamicPageGUI: React.FC<DynamicGUIProps> = ({ gui}) => {

    const componentMap: { [key: string]: React.FC<any> } = {
        "Row": Row,
        "Col": Col,
        "Div": Div,
        "H5":H5,
        "DisplayPreviewer":DisplayPreviewer,
        "MultiLine":MultiLine,
        "PageOperations":PageOperations,
        "PageDurationSelector":PageDurationSelector,
        "RtEditor": RtEditor,
        "MultiLineSplittableForm": MultiLineSplittableForm,
        "MultiLineSplittableForm2": MultiLineSplittableForm2
    };

    const {t} = useTranslation();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(gui, "text/xml");
    const rootElement = xmlDoc.documentElement as unknown as XMLNode;
    return parseXMLToReact(rootElement,0,t,componentMap);
};
