import React from "react";
import Form from "react-bootstrap/Form";
import {useFormContext, useWatch} from "react-hook-form";
import {useDynamicPageGUIContext} from "../DynamicPageGUIContext";
import {ButtonGroup, ButtonToolbar, Col, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {SubHeadline} from "../../../components/subheadline/SubHeadline";
import {useTranslation} from "react-i18next";
import {TemplatePage} from "../../TemplatePage";


export const MultiLineSplittableForm = () => {
    const {t} = useTranslation();
    const {
        register,
        control,
        formState: {errors}
    } = useFormContext()
    const {pageNumber} = useDynamicPageGUIContext();

    const templateParameter = useWatch({
        control,
        name: "tpl.parameter"
    });

    const pageContent = useWatch({
        control,
        name: "tpl.pages." + pageNumber
    });

    const numLines = templateParameter.numLines;
    const numCharsPerLine = templateParameter.numCharsPerLine;
    const splittings = templateParameter.opticalLineSplitting;
    const rows = [];

    for (let y = 0; y < numLines; y++) {
        let cols = [];
        let sVal = parseInt(splittings[y].splitAt);
        let text = "";
        if (pageContent.content[y]) {
            text = pageContent.content[y].text || "\u00A0";
        }
        let textArray = text.replaceAll(" ", "\u00A0").split("");
        cols.push(<div
            className={"d-none d-lg-inline-block mt-1 font-monospace p-1 pe-3"}>{y + 1 < 10 ? "\u00A0" + (y + 1) : y + 1}</div>);
        for (let x = 0; x < numCharsPerLine; x++) {
            let letter = textArray[x] || "\u00A0";
            cols.push(<div
                className={((x >= sVal - 1) ? "bg-warning" : " bg-info") + " mt-1 d-inline-block font-monospace p-1 border"}>{letter}</div>);
        }
        rows.push(<div className={"mt-1 d-md-none"}>{t("row")} {y + 1}</div>);
        rows.push(<div>{cols}</div>);
    }


    const getLines = () =>
        Array.from({length: numLines}, (_, i) => <div key={i} className={"pb-1"}>
            <Row>
                <Col>
                    {
                        <InputGroup>
                            <InputGroup.Text className={"font-monospace"}
                                             id="basic-addon1">{t("row")} {i + 1 < 10 ? "\u00A0" + (i + 1) : i + 1}</InputGroup.Text>
                            <Form.Control type={"text"}  {...register(`tpl.pages.${pageNumber}.content.${i}.text`)}
                                          className="font-monospace form-control rounded-0" data-1p-ignore/>
                        </InputGroup>
                    }
                </Col>
            </Row>
        </div>);

    return (<><SubHeadline>{t("preview")}</SubHeadline>
        <Row>
            <Col className="col-12">
                <div className="border border-primary p-2">
                    <div className="my-4 text-md-center font-monospace">
                        {rows}
                    </div>
                </div>
            </Col>
        </Row>

        <div className={"pt-3"}>
            <ButtonToolbar className={"mb-2"}>
                <ButtonGroup className="me-2" size={"sm"}>
                    <Button variant="secondary">Blinken</Button>
                    <Button variant="secondary">Breitschrift</Button>
                </ButtonGroup>
            </ButtonToolbar>
            {getLines()}
        </div>
    </>);
}