import React, {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {RtToolbar} from "./RtToolbar";
import {RtEditorArea} from "./RtEditorArea";

export const EditAreaAndToolbar = () => {
    const {control} = useFormContext()
    const [toolbarButtonClickData, setToolbarButtonClickData] = useState({});
    const templateParameter = useWatch({
        control,
        name: "tpl.parameter"
    });

    const hasMultiColor = templateParameter.multiColor === true;
    const hasBold = templateParameter.bold === true;
    const hasFlash = templateParameter.flash === true;

    return (
        <div>
            <RtToolbar setToolbarButtonClickData={setToolbarButtonClickData}
                       toolbarButtonClickData={toolbarButtonClickData}
                       hasMultiColor={hasMultiColor} hasBold={hasBold}
                       hasFlash={hasFlash}/>
            <RtEditorArea toolbarButtonClickData={toolbarButtonClickData}/>
        </div>
    );
}