
import React from "react";
import PropTypes from "prop-types";

const DuplicateInfo= (props:{serviceUrl:string}) => <></>;

DuplicateInfo.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string,
};

DuplicateInfo.defaultProps = {
    __TYPE: 'DuplicateInfo',
};


export default DuplicateInfo;