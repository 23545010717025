import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {useDynamicPageGUIContext} from "../../DynamicPageGUIContext";
import {ContentEditableWithCounter} from "./ContentEditableWithCounter";
import {PairedContentEditableWithCounter} from "./PairedContentEditableWithCounter";


export const RtEditorArea = (props: {toolbarButtonClickData:any}) => {
    const {watch} = useFormContext()
    const [flash, setFlash] = useState(false);
    const numLines = watch("tpl.parameter.numLines");
    const numChars = watch("tpl.parameter.numCharsPerLine");
    const {pageNumber} = useDynamicPageGUIContext();

    let fCounter =0;
    useEffect(() => {
        const interval = setInterval(() => {
            setFlash((fCounter++) % 2 === 0);
        }, 1300);
        return () => {
            clearInterval(interval);}
    }, []);

    const numIterations: number = Math.floor(numLines / 2);
    const rest: number = numLines % 2;

    return (
        <>
            {Array.from({length: numIterations}, (item, i) => {
                    return <PairedContentEditableWithCounter key={i} flashTextVisible={flash}
                                                             pageNumber={pageNumber}
                                                             idex={i} charLimit={numChars}
                                                             toolbarButtonClickData={props.toolbarButtonClickData}/>
                }
            )}
            {rest > 0 && <div className={"mt-1"}><ContentEditableWithCounter
                flashTextVisible={flash} clsName={"led-text led-text-red"}
                name=""  charLimit={numChars} toolbarButtonClickData={props.toolbarButtonClickData}/></div>}
        </>
    );
}