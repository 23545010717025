import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DynamicParameterGUIContextType {
    fields: any,
    updateNumLines:any
}

const DynamicParameterGUIContext = createContext<DynamicParameterGUIContextType | undefined>(undefined);

export const DynamicParameterGUIContextProvider: React.FC<{ fields:any,updateNumLines:any, children: ReactNode }> = ({ fields, updateNumLines, children }) => {
    return (
        <DynamicParameterGUIContext.Provider value={{ fields,updateNumLines }}>
            {children}
        </DynamicParameterGUIContext.Provider>
    );
};


export const useDynamicParameterGUIContext = (): DynamicParameterGUIContextType => {
    const context = useContext(DynamicParameterGUIContext);
    if (!context) {
        throw new Error('useDynamicParameterGUIContext must be used within a DynamicParameterGUIContextProvider');
    }
    return context;
};