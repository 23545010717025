import React from "react";
import {DisplayModel} from "../../models/DisplayModel";
import {DashboardDisplayTileItem} from "./dashboard/DashboardDisplayTileItem";
import Masonry from "react-layout-masonry";


export const DashboardDisplayTiles = (props: { displayGroup: any,setReload:any }) => {
    return (

        <Masonry
            columns={{ 640: 1, 768: 2, 1024: 3, 1280: 3 }}
            gap={16}
        >
            {props.displayGroup.displays.map((display: DisplayModel) => (
                <DashboardDisplayTileItem key={display.id} displayGroupId={props.displayGroup.id} display={display} setReload={props.setReload}/>
            ))}
        </Masonry>


       );

}
