import React from "react";
import {useTranslation} from "react-i18next";
import {DynamicThumbnailGUI} from "./thumbnailGui/DynamicThumbnailGUI";
import {DynamicThumbnailGUIContextProvider} from "./thumbnailGui/DynamicThumbnailGUIContext";
import {DisplayModel} from "../models/DisplayModel";


export const ThumbnailManager = (props: { display:DisplayModel}) => {
    const {t} = useTranslation();

    return (<>
        <DynamicThumbnailGUIContextProvider display={props.display}>
            <DynamicThumbnailGUI gui={props.display.thumbnailGui}/>
        </DynamicThumbnailGUIContextProvider>
           </>);
}